.#{$prefix}accordion {
  background: v(bg-color);
  border-top: 1px solid v(color-secondary-04);
  .item {
    border-bottom: 1px solid v(color-secondary-04);
    display: flex;
    flex-direction: column;
    &[active] {
      border-bottom: 0;
      .header {
        font-weight: v(font-weight-semi-bold);
      }
      & + .content {
        border-bottom: 1px solid v(color-secondary-04);
        display: block;
      }
    }
  }
  .header {
    --bg-color: transparent;
    --text-color: var(--color-primary-default);
    background: v(bg-color);
    border: 0;
    color: v(text-color);
    display: flex;
    font-size: v(font-size-scale-up-01);
    justify-content: space-between;
    padding: v(spacing-scale-2x) 0;
    text-align: left;
    text-decoration: none;
    width: 100%;
    outline: none;
    .icon {
      margin: 0 v(spacing-scale-2x) 0 v(spacing-scale-baseh);
    }
    .title {
      flex: 1;
      margin: 0;
    }
    @include focus;
    @include hover;
    @include active;
  }
  .content {
    color: v(text-color);
    display: none;
    font-size: v(font-size-scale-base);
    margin: 0 v(spacing-scale-base);
    padding: v(spacing-scale-base) v(spacing-scale-8x) v(spacing-scale-2x);
    * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &[negative] {
    --bg-color: var(--color-primary-darken-02);
    --text-color: var(--color-secondary-01);
    --status-focused-background: var(--color-primary-darken-01);
    --status-hover-background: var(--color-primary-default);
    .header {
      --text-color: var(--color-secondary-01);
    }
  }
}
