// stylelint-disable

@supports (display: grid) {
  .template-system,
  .template-site,
  .template-authentication {
    display: grid;
    grid-template-areas:
      "header"
      "wrapper"
      "footer";
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
    .page-header {
      grid-area: header;
    }
    .page-wrapper {
      grid-area: wrapper;
    }
    .page-footer {
      grid-area: footer;
    }
  }
  .template-system {
    .page-wrapper {
      display: grid;
      grid-template-columns: auto 1fr;
    }
  }
}

// Estilo padrão do texto gov.br
.govbr {
  font-weight: v(font-weight-extra-bold, $font-weight-extra-bold);
  letter-spacing: -1px;
}

// Voltar para topo
.is-go-top {
  align-items: center;
  background: transparent;
  border: 0;
  display: inline-flex;
  font-weight: v(font-weight-extra-bold, $font-weight-extra-bold);
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

// Barra principal
$main-bar-mobile: 45px;
$main-bar-tablet: 85px;
$main-bar-desktop: 150px;
$main-bar-desktop-fixed: 55px;

$navigation-bar: 64px;
$navigation-width: 320px;

$logo-mobile: 64px;
$logo-desktop: 150px;

// Sombra
.shadow-bottom {
  box-shadow: 0 3px 6px rgba($color-secondary-09, 0.16); // sass-lint:disable-line no-color-literals
}

// Fixar na tela
.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

// Barra do cabeçalho
.header-bar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: $main-bar-mobile;
  margin: 0 (-$spacer);
  position: relative;
}

// Barra de sistema
.system-bar {
  align-items: center;
  height: 60px;
}

// Tempo restante de sessão
.timeout {
  display: none;
  .session {
    font-size: 12px;
    font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
  }
  .timer {
    font-size: 12px;
    font-weight: v(font-weight-medium, $font-weight-medium);
  }
}

// Links úteis
.links {
  border-top: 1px solid v(color-secondary-03, $color-secondary-03);
  font-size: 14px;
  padding: $spacer;
  position: relative;
  span {
    display: block;
    font-weight: v(font-weight-bold, $font-weight-bold);
    margin: $spacer 0;
    text-transform: uppercase;
  }
  a {
    align-items: center;
    display: flex;
    padding: $spacer * 0.5 0;
    [class*="fa-"] {
      margin-left: $spacer;
    }
  }
}

// Redes sociais
.social-network {
  border-top: 1px solid v(color-secondary-03, $color-secondary-03);
  margin: 0;
  padding: $spacer;
  text-transform: uppercase;
  span {
    display: block;
    font-size: 14px;
    font-weight: v(font-weight-bold, $font-weight-bold);
    margin-right: ($spacer * 2.5);
  }
  ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin: 0;
      padding: 0;
      a {
        color: v(color-primary-default, $color-primary-default);
        display: inline-block;
        padding: $spacer ($spacer * 2);
      }
      &:first-child {
        a {
          padding-left: 0;
        }
      }
      &:last-child {
        a {
          padding-right: 0;
        }
      }
    }
  }
}

// Rodape
// stylelint-disable no-descending-specificity
.page-footer {
  position: relative;
  a {
    color: v(color-secondary-01, $color-secondary-01);
  }
  // Logo
  .logo {
    display: block;
    padding-bottom: ($spacer * 5);
    padding-top: ($spacer * 7);
    position: static;
    text-align: left;
    img {
      height: 60px;
      max-width: none;
      width: 166px;
    }
  }
  // Navegação
  .navigation {
    border-top: 1px solid v(color-secondary-01, $color-secondary-01);
    padding: 0;
    position: static;
    // Item de navegação
    .item {
      border-bottom: 1px solid v(color-secondary-01, $color-secondary-01);
      a,
      button {
        color: v(color-secondary-01, $color-secondary-01);
        display: inline-block;
        font-weight: v(font-weight-extra-bold, $font-weight-extra-bold);
        padding: ($spacer * 2) 0;
        text-transform: uppercase;
        width: auto;
        &:hover {
          background: transparent;
          color: v(status-hover-background, $status-hover-background);
          text-decoration: underline;
        }
      }
      // Navegação ativa
      &.is-active {
        a,
        button {
          background: transparent;
        }
      }
    }
    // Submenu
    .submenu {
      background: transparent;
      position: static;
    }
    // Nível 1
    .level-1 {
      padding-bottom: ($spacer * 1.5);
      a,
      button {
        font-size: 14px;
        font-weight: v(font-weight-medium, $font-weight-medium);
        padding: $spacer 0;
        text-transform: none;
      }
      .item {
        border-bottom: 0;
      }
    }
    // Não mostrar a partir de nivel 2
    .level-2 {
      display: none;
    }
  }
  // Redes sociais
  .social-network {
    border: 0;
    color: v(color-secondary-01, $color-secondary-01);
    padding: ($spacer * 7) 0 ($spacer * 2);
    text-align: left;
    span {
      display: block;
    }
    a {
      color: v(color-secondary-01, $color-secondary-01);
    }
  }
  // Marcas do governo
  #footer-brasil {
    background: transparent;
    width: auto;
    #wrapper-footer-brasil {
      justify-content: flex-start;
      max-width: 100%;
      padding: ($spacer * 7) 0 ($spacer * 4);
      width: auto;
    }
    .logo-acesso-footer,
    .logo-governo-federal {
      filter: invert(100%);
    }
  }
  // Cancela Serpro
  .chancela-serpro {
    border-bottom: 4px solid v(color-primary-darken-02, $color-primary-darken-02);
    padding: $spacer;
    text-align: center;
  }
}
// Ajustes para sistemas
[data-type="system"] {
  // Barra do cabeçalho
  .header-bar {
    height: $main-bar-mobile * 2;
  }
  // Logo
  .logo {
    align-items: flex-start;
    align-self: flex-start;
    flex-direction: column-reverse;
    padding: 0 ($spacer * 1.5);
    position: static;
    .name {
      display: block;
      font-size: 20px;
    }
    .description {
      display: block;
      font-size: 12px;
    }
  }
  // Links rápidos
  .quicklinks {
    display: none;
  }
  // Busca
  .search {
    top: $main-bar-mobile;
    // Busca ativa
    &.is-active {
      bottom: $main-bar-mobile;
      top: 0;
    }
  }
  // Menu sanduiche
  .menu-hamburger {
    top: $main-bar-mobile;
  }
}

// Versao tablet
@include media-breakpoint-up(sm) {
  // Barra do cabeçalho
  .header-bar {
    height: $main-bar-tablet;
  }
  // Ajustes para sistemas
  [data-type="system"] {
    // Barra do cabeçalho
    .header-bar {
      height: $main-bar-mobile * 2;
    }
    // Logo
    .logo {
      display: flex;
      .description {
        margin: 0;
      }
    }
    // Busca
    .search {
      bottom: 0;
    }
    // Menu sanduiche
    .menu-hamburger {
      bottom: 0;
    }
  }
}

// Versao desktop
@include media-breakpoint-up(md) {
  // Barra do cabeçalho
  .header-bar {
    height: $main-bar-desktop;
  }
  // Configurações do usuário
  .nav-configs {
    display: none;
  }
  // Rodape
  .page-footer {
    // Navegação
    .navigation {
      border-top: 0;
      &::after {
        content: none;
      }
      // Menu local
      .local {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      // Item de navegação
      .item {
        border-bottom: 0;
        flex: 0 0 16.66667%;
        padding-right: ($spacer * 2);
        width: auto;
        > a,
        > button {
          display: inline-block;
          width: auto;
          &:hover {
            text-decoration: underline;
          }
        }
        &:hover,
        &:active {
          > a,
          > button {
            background: transparent;
            font-weight: v(font-weight-extra-bold, $font-weight-extra-bold);
          }
        }
        .item {
          &:hover,
          &:active {
            > a,
            > button {
              font-weight: v(font-weight-medium, $font-weight-medium);
            }
          }
        }
      }
      // Níveis de navegação
      .level-1,
      .level-2,
      .level-3 {
        box-shadow: none;
      }
      .level-1 {
        background: transparent;
        display: block;
        margin: 0;
        padding: 0;
        position: static;
        width: auto;
        .item {
          &:hover {
            .submenu {
              display: none;
            }
          }
        }
      }
    }
    .chancela-serpro {
      bottom: 100%;
      left: 0;
      position: absolute;
      right: 0;
      text-align: right;
    }
  }
  // stylelint-enable no-descending-specificity
  // Ajustes para sistemas
  [data-type="system"] {
    // Barra do cabeçalho
    .header-bar {
      height: $main-bar-desktop;
      margin: 0;
    }
    // Logo
    .logo {
      flex: 0 0 50%;
      height: 50%;
      padding: 0;
      .name {
        margin: 0;
        position: static;
      }
      .description {
        margin: 0;
        position: static;
      }
    }
    // Links rápidos de acessibilidade e idioma
    .quicklinks {
      align-items: center;
      display: inline-flex;
      flex: 0 0 50%;
      height: 50%;
      margin: 0;
    }
    // Busca
    .search {
      bottom: auto;
      margin-bottom: 0;
      right: 300px;
      top: 84px;
      &.is-active {
        bottom: auto;
        left: 0;
        top: 84px;
      }
    }
    // Barra do usuário
    .user-bar {
      flex: 1;
      height: 50%;
      justify-content: flex-end;
    }
    // Menu sanduiche
    .menu-hamburger {
      bottom: 22px;
      left: -($spacer * 1.5);
      top: auto;
    }
    // Wrapper de sistemas
    & + .page-wrapper {
      flex-direction: row;
    }
  }
}

.br-search {
  & .br-input {
    width: 100%;
  }
}

// stylelint-enable
