// Breakpoint
$grid-breakpoint-xs: 0px;
$grid-breakpoint-sm: 575px;
$grid-breakpoint-md: 992px;
$grid-breakpoint-lg: 1280px;
$grid-breakpoint-xl: 1600px;
$tokens-breakpoints: (
  grid-breakpoint-xs: $grid-breakpoint-xs,
  grid-breakpoint-sm: $grid-breakpoint-sm,
  grid-breakpoint-md: $grid-breakpoint-md,
  grid-breakpoint-lg: $grid-breakpoint-lg,
  grid-breakpoint-xl: $grid-breakpoint-xl,
);

// Portrait
$grid-portrait-maxwidth: 100%;
$grid-portrait-gutter: 16px;
$grid-portrait-columns: 4;
$grid-portrait-margin: 8px;
$tokens-portrait: (
  grid-portrait-maxwidth: $grid-portrait-maxwidth,
  grid-portrait-gutter: $grid-portrait-gutter,
  grid-portrait-columns: $grid-portrait-columns,
  grid-portrait-margin: $grid-portrait-margin,
);

// Tablet
$grid-tablet-maxwidth: 100%;
$grid-tablet-gutter: 24px;
$grid-tablet-columns: 8;
$grid-tablet-margin: 40px;
$tokens-tablet: (
  grid-tablet-maxwidth: $grid-tablet-maxwidth,
  grid-tablet-gutter: $grid-tablet-gutter,
  grid-tablet-columns: $grid-tablet-columns,
  grid-tablet-margin: $grid-tablet-margin,
);

// Desktop
$grid-desktop-maxwidth: 1200px;
$grid-desktop-gutter: 24px;
$grid-desktop-columns: 12;
$grid-desktop-margin: 40px;
$tokens-desktop: (
  grid-desktop-maxwidth: $grid-desktop-maxwidth,
  grid-desktop-gutter: $grid-desktop-gutter,
  grid-desktop-columns: $grid-desktop-columns,
  grid-desktop-margin: $grid-desktop-margin,
);

// TV
$grid-tv-maxwidth: 1520px;
$grid-tv-gutter: 40px;
$grid-tv-columns: 12;
$grid-tv-margin: 40px;
$tokens-tv: (
  grid-tv-maxwidth: $grid-tv-maxwidth,
  grid-tv-gutter: $grid-tv-gutter,
  grid-tv-columns: $grid-tv-columns,
  grid-tv-margin: $grid-tv-margin,
);

// Mapa de breakpoints
$breakpoints: (
  portrait: $grid-breakpoint-xs,
  tablet: $grid-breakpoint-sm,
  desktop: $grid-breakpoint-lg,
  tv: $grid-breakpoint-xl,
);

// Compatibilidade com Bootstrap
$grid-gutter-width: $grid-portrait-gutter;
$grid-breakpoints: (
  xs: 0,
  sm: $grid-breakpoint-sm,
  md: $grid-breakpoint-md,
  lg: $grid-breakpoint-lg,
  xl: $grid-breakpoint-xl,
);
$container-max-widths: (
  sm: $grid-desktop-maxwidth - 2,
  md: $grid-desktop-maxwidth - 1,
  lg: $grid-desktop-maxwidth,
  xl: $grid-tv-maxwidth,
);
