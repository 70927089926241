.#{$prefix}message {
  display: flex;
  margin-bottom: v(spacing-scale-2x);
  width: 100%;
  .icon {
    align-self: center;
    padding: v(spacing-scale-2x) 0;
    text-align: center;
    width: v(icon-size-3x);
    img {
      max-width: 32px;
    }
  }
  .content {
    align-self: center;
    flex: 1;
    font-size: v(font-size-scale-up-01);
    padding: v(spacing-scale-2x) 0;
    a {
      @include focus;
      @include hover;
    }
    * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .close {
    align-self: start;
    margin: v(font-size-scale-base);
  }
  // Estados
  @each $state, $color in (success, v(color-success)), (danger, v(color-danger)),
    (warning, v(color-warning)), (info, v(color-info))
  {
    &.is-#{$state},
    &.#{$state},
    &[#{$state}] {
      background: rgba(var(--rgb-#{$state}), 0.09);
      .icon {
        color: $color;
      }
      .close {
        .#{$prefix}button {
          color: $color;
          &:hover {
            background: rgba(var(--rgb-#{$state}), 0.15);
          }
        }
      }
      @if ($state == warning) {
        .icon {
          color: v(text-color);
        }
        .close {
          .#{$prefix}button {
            color: v(text-color);
          }
        }
      }
    }
  }
}
