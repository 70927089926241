:root {
  --font-family-base: #{$font-family-base};
  @include tokens($tokens-colors);
  @include tokens($tokens-icons);
  @include tokens($tokens-status);
  @include tokens($tokens-font-weight);
  @include tokens($tokens-font-size);
  @include tokens($tokens-line-height);
  @include tokens($tokens-breakpoints);
  @include tokens($tokens-portrait);
  @include tokens($tokens-tablet);
  @include tokens($tokens-desktop);
  @include tokens($tokens-tv);
  @include tokens($tokens-alinhamento);
  @include tokens($tokens-spacing);
}
