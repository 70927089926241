.#{$prefix}wizard {
  height: calc(100vh - 52px);
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  .wizard-progress {
    display: grid;
    grid-template-rows: none;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr)); //Padrao horizontal
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: v(color-secondary-02, $color-secondary-02);
    min-height: 164px;
    padding-top: v(spacing-scale-7x);

    .wizard-progress-btn {
      transition-property: all;
      transition-duration: 0.15s;
      transition-timing-function: linear;
      transition-delay: 0s;
      position: relative;
      color: v(color-primary-lighten-01, $color-primary-lighten-01);
      text-indent: 0;
      background-color: transparent;
      border: none;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      outline: none !important;
      cursor: pointer;
      font-size: v(font-size-scale-up-01, $font-size-scale-up-01);
      line-height: 19px;
      padding-top: v(spacing-scale-2x);
      padding-bottom: 0px;
      max-height: 90px;
      &:before {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: 36px;
        height: 36px;
        margin-top: -13px;
        font-size: v(font-size-scale-up-02, $font-size-scale-up-02);
        font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
        line-height: 29px;
        content: attr(step) !important;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        transition: all 0.15s linear 0s,
          -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s,
          -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        border: 2px solid v(color-primary-lighten-01, $color-primary-lighten-01);
        color: v(color-primary-lighten-01, $color-primary-lighten-01);
        border-radius: 50%;
        background-color: v(color-secondary-01, $color-secondary-01);
        box-sizing: border-box;
        z-index: 3;
      }

      &:after {
        position: absolute;
        top: 5px;
        left: calc(-50% + 34px / 2);
        transition-property: all;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        transition-delay: 0s;
        display: block;
        width: calc(100% - 34px);
        height: 1px;
        content: '';
        background-color: v(color-primary-lighten-01, $color-primary-lighten-01);
        z-index: 1;
      }

      &:first-child:after {
        display: none;
      }

      &[active] {
        color: v(color-primary-default, $color-primary-default);
        font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
      }

      &[inactive] {
        color: v(color-primary-default, $color-primary-default);
      }

      &[disabled] {
        opacity: 0.5;
      }

      &[active]:after,
      &[inactive]:after {
        height: 2px;
      }

      &[inactive]:before {
        background-color: v(color-primary-pastel-01, $color-primary-pastel-01);
        border-color: v(color-primary-pastel-01, $color-primary-pastel-01);
        color: v(color-primary-default, $color-primary-default);
      }

      &[active]:before,
      &[active]:hover:before,
      &[active]:focus:before {
        background-color: v(color-primary-default, $color-primary-default);
        border-color: v(color-primary-default, $color-primary-default);
        color: v(color-secondary-01, $color-secondary-01);
        font-weight: v(font-weight-bold, $font-weight-bold);
      }

      &[inactive]:hover:before {
        background-color: v(status-hover-background, $status-hover-background);
        border-color: v(status-hover-background, $status-hover-background);
        color: v(color-primary-lighten-01, $color-primary-lighten-01);
      }

      &[disabled]:hover:before,
      &[disabled]:focus:before {
        background-color: default !important;
        border-color: default !important;
      }

      &:hover:before {
        background-color: v(status-hover-background, $status-hover-background);
        border-color: v(color-primary-lighten-01, $color-primary-lighten-01);
      }

      &:focus:before {
        background-color: v(status-focused-overlay, $status-focused-overlay);
        border-color: v(color-primary-lighten-01, $color-primary-lighten-01);
      }

      &[inactive]:focus:before {
        background-color: v(status-focused-overlay, $status-focused-overlay);
        border-color: v(status-focused-overlay, $status-focused-overlay);
        color: v(color-primary-lighten-01, $color-primary-lighten-01);
      }
    }
  }

  .wizard-form {
    position: relative;

    .wizard-panel {
      position: absolute;
      top: 0;
      left: 1%;
      height: 0;
      opacity: 0;
      visibility: hidden;
      border-top: 1px solid v(color-secondary-04, $color-secondary-04);
      border-bottom: 1px solid v(color-secondary-04, $color-secondary-04);
      margin-left: 2%;
      width: 96%;
      margin-bottom: v(spacing-scale-4x);
      &[active] {
        height: auto;
        opacity: 1;
        visibility: visible;
        transition-property: all;
        transition-duration: 0.5s;
        transition-timing-function: linear;
        transition-delay: 0s;
        left: 0 !important;
      }
      .wizard-panel-content {
        min-height: calc(100vh - 300px);
        max-height: calc(100vh - 300px);
        overflow-y: auto;
        overflow-x: hidden;
      }
      .wizard-panel-btn {
        width: 104.5%;
        position: absolute;
        margin-top: 1px;
        min-height: 88px;
        height: auto;
        padding: v(spacing-scale-2x) 1.5%;
        background-color: v(color-secondary-02, $color-secondary-02);
        margin-left: -2.5%;
      }
      .wizard-btn-next,
      .wizard-btn {
        float: right;
      }

      .wizard-btn-prev {
        float: left;
      }
    }
  }

  &[vertical] {
    .wizard-progress {
      max-width: 260px;
      height: calc(100vh - 52px);
      float: left;
      grid-template-columns: 1fr; //Padrão vertical
      text-align: right;
      padding-top: 0;
      position: relative;

      .wizard-progress-btn {
        padding-top: 0;
        padding-bottom: 0px;
        line-height: 100%;
        text-align: right;
        padding-right: 70px;
        height: 100%;
        max-height: 100%;

        &:before {
          position: absolute;
          top: 50%;
          left: calc(100% - 32px);
          margin-top: calc(#{v(spacing-scale-2x)} * -1);
          line-height: 26px;
          text-align: center;
        }

        &:after {
          position: absolute;
          top: calc(-50% + 36px / 2);
          left: calc(100% - 33px);
          width: 1px;
          height: calc(100% - 34px);
        }
        &[active]:after,
        &[inactive]:after {
          width: 2px;
        }
      }
    }
    &[scroll] {
      .wizard-progress {
        overflow-x: hidden;
        overflow-y: auto;
        .wizard-progress-btn {
          min-height: 100px;
        }
      }
    }
    .wizard-form {
      float: left;
      width: calc(100% - 260px);
      .wizard-panel {
        .wizard-panel-content {
          min-height: calc(100vh - 142px);
          max-height: calc(100vh - 142px);
        }
        .wizard-panel-btn {
          margin-left: -2%;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .wizard-form {
      .wizard-panel {
        border-top: 0;
        .wizard-panel-content {
          min-height: calc(100vh - 276px);
          max-height: calc(100vh - 276px);
          transition-property: all;
          transition-duration: 0.25s;
          transition-timing-function: linear;
          transition-delay: 0s;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
      .wizard-panel-btn {
        width: 104%;
        margin-left: -2%;
        padding: 0 !important;
        background-color: transparent;
        //position: relative;
        .br-button {
          width: 90%;
          margin: v(spacing-scale-base) 5%;
          float: left;
          clear: both;
        }
      }
    }
    .wizard-progress {
      position: relative;
      padding-top: v(spacing-scale-3x);
      min-height: 100px;
      max-height: 100px;
      transition-property: all;
      transition-duration: 0.25s;
      transition-timing-function: linear;
      transition-delay: 0s;
      overflow-y: hidden;
      .wizard-progress-btn {
        .info {
          font-size: v(font-size-scale-down-02, $font-size-scale-down-02);
          line-height: 1em;
          text-align: center;
        }
      }
      &:after {
        position: absolute;
        display: block;
        width: 12%;
        height: 4px;
        border-radius: 2px;
        content: '';
        background-color: v(color-secondary-04, $color-secondary-04);
        bottom: 0;
        top: unset;
        left: 44%;
        transition-property: all;
        transition-duration: 0.25s;
        transition-timing-function: linear;
        transition-delay: 0s;
      }
    }

    &[collapsed] {
      .wizard-progress {
        min-height: 64px;
        max-height: 64px;
        padding-bottom: v(spacing-scale-3x);
        overflow-y: hidden;
        padding-top: v(spacing-scale-3x);

        &:after {
          margin-top: v(spacing-scale-4xh);
        }
        .wizard-progress-btn {
          padding-bottom: v(spacing-scale-base);
          .info {
            display: none;
          }
        }
      }
      .wizard-form {
        .wizard-panel {
          .wizard-panel-content {
            min-height: calc(100vh - 240px);
            max-height: calc(100vh - 240px);
            overflow-y: auto;
            overflow-x: hidden;
          }
        }
      }
    }

    &[vertical] {
      .wizard-progress {
        max-width: 110px;
        max-height: calc(100vh - 60px);
        &:after {
          width: 4px;
          height: 8%;
          //left: 106px;
          left: unset;
          right: 0;
          top: 40%;
          margin-top: 0;
        }
        .wizard-progress-btn {
          padding-right: v(spacing-scale-7x);
          line-height: 18px;
          .info {
            position: absolute;
            right: 54px;
            margin-top: -0.5em;
            line-height: 1.6em;
          }
        }
      }
      .wizard-form {
        max-width: calc(100% - 110px);
        min-width: calc(100% - 110px);
        transition-property: all;
        transition-duration: 0.25s;
        transition-timing-function: linear;
        transition-delay: 0s;
        .wizard-panel {
          .wizard-panel-content {
            min-height: calc(100vh - 180px);
            max-height: calc(100vh - 180px);
          }
          .wizard-panel-btn {
            min-height: 119px;
          }
        }
      }
      &[collapsed] {
        .wizard-progress {
          padding-top: 0;
          padding-bottom: 0;
          max-width: 60px;
          &:after {
            width: 4px;
            height: 8%;
            //left: 54px;
            left: unset;
            right: 0;
            top: 40%;
          }
          .wizard-progress-btn {
            padding-right: 0;
            .info {
              display: none;
            }
          }
        }
        .wizard-form {
          min-width: calc(100% - 60px);
          max-width: calc(100% - 60px);
        }
      }
    }
  }
}
