.#{$prefix}card {
  box-shadow: 0 3px 6px rgba(v(rgb-secondary-09), 0.16);
  margin-bottom: v(spacing-scale-2x);
  > .front,
  > .back {
    background: v(bg-color);
    color: v(text-color);
    display: flex;
    flex-flow: column;
    > .header,
    > .content,
    > .footer {
      padding: v(spacing-scale-2x);
      &:empty {
        padding: 0;
      }
      *:last-child {
        margin-bottom: 0;
      }
    }
    > .content {
      flex: 1;
    }
    > .header + .content,
    > .content + .footer {
      padding-top: 0;
    }
  }
  > .back {
    --bg-color: var(--color-secondary-07);
    --text-color: var(--color-secondary-01);
    --status-hover-background: var(--color-secondary-06);
    --status-highlight-background: var(--color-secondary-07);
  }
  &[flipped] {
    > .front,
    > .back {
      overflow: hidden;
      transition: opacity 600ms ease;
    }
  }
  &[flipped="on"] {
    > .front {
      height: 0;
      opacity: 0;
    }
    .br-button.flip {
      color: v(button-color);
      &:hover {
        color: v(color-secondary-01);
      }
    }
  }
  &[flipped="off"] {
    > .back {
      height: 0;
      opacity: 0;
    }
  }
  &[expanded] {
    .expanded {
      overflow: hidden;
    }
  }
  &[expanded="on"] {
    .expanded {
      height: 100%;
      opacity: 1;
    }
  }
  &[expanded="off"] {
    .expanded {
      opacity: 0;
      height: 0;
      z-index: -1;
    }
  }
  &:disabled,
  &[disabled] {
    opacity: 0.45;
    * {
      pointer-events: none;
    }
  }
}
