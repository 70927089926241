.template-base {
  .accessibility-links {
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
    a {
      position: absolute;
      top: -9999px;
      z-index: 9999;
      &:focus {
        top: 0;
      }
    }
  }

  .#{$prefix}header {
    z-index: 1;
    position: relative;
  }

  .#{$prefix}header.sticky {
    z-index: 99999;
    & + div {
      position: relative;
      z-index: auto;
    }
  }

  .context-menu {
    .header {
      display: none;
    }
  }

  .context-menu .#{$prefix}menu,
  .container-main {
    height: auto !important;
    min-height: calc(100vh - 240px);
  }

  .#{$prefix}breadcrumb {
    margin-top: v(spacing-scale-2x);
    margin-bottom: v(spacing-scale-2x);
  }

  .#{$prefix}footer {
    position: relative;
    z-index: 0;
    .logo {
      padding: v(spacing-scale-2x) 0;
    }
    .#{$prefix}list {
      .#{$prefix}list {
        background-image: none !important;
      }
    }
  }

  .scrim-menu {
    background-color: rgba(v(rgb-secondary-09), 0.4);
    bottom: 0;
    left: 0;
    position: fixed;
    top: 0;
    width: 0;
    height: 0;
    z-index: 999;
    overflow: hidden;
    .#{$prefix}menu {
      max-width: 0;
      height: 100vh;
      padding: 0;
    }
    &[show] {
      width: 100vw;
      height: 100vh;
      .#{$prefix}menu {
        max-width: 100%;
        width: fit-content;
        width: -moz-fit-content;
        height: 100vh;
        transition: all 0.5s ease-in-out;
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }
  }

  // .#{$prefix}notification[notification] {
  //   //margin-left: 4px;
  //   &[data-popper-placement^='bottom'] > .arrow {
  //     margin-left: 24px !important;
  //   }
  // }

  @include media-breakpoint-down(sm) {
    .context-menu {
      position: fixed;
      z-index: 2;
      left: 0;
      bottom: 0;
      width: 100vw;
      max-width: 100vw;
      height: 40px;
      overflow: hidden;
      margin-left: 0;
      padding: 0;
      box-shadow: 3px 0 6px rgba(var(--rgb-secondary-09), 0.16);
      .#{$prefix}menu {
        //min-height: 100%;
        max-height: 50vh;
        width: 100%;
        min-height: fit-content;
        min-height: -moz-fit-content;
      }
      &[show] {
        //height: fit-content;
        background-color: rgba(v(rgb-secondary-09), 0.4);
        height: 100vh;
        display: flex;
        align-items: flex-end;
      }
      .header {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .folders {
        max-height: calc(50vh - 40px);
        overflow: auto;
      }
    }

    .container-main {
      height: auto !important;
      min-height: calc(100vh - 260px);
    }

    .#{$prefix}footer {
      padding-bottom: 43px;
    }
  }
}
