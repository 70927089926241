/*!
 * DSGov: Design System de Governo
 * Versão beta
 * Suporte:
   - Serpro: DIDES/SUPSE/SESEP
 */

// Carregar configurações
@import "base";

// Gerar tokens
@import "_tokens.scss";

// Utilitários
@import "utilities";

// Templates
@import "templates";

// Componentes
@import "components";
