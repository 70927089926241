.#{$prefix}upload {
  input,
  label {
    display: none;
  }

  .br-button.upload-button {
    width: 100%;
    max-width: 550px;
    &:active * {
      color: v(color-primary-pastel-01);
    }
  }
  .upload-button {
    border: 1px dashed;
    border-radius: 4px;
    padding-left: v(spacing-scale-2x);
    padding-right: v(spacing-scale-2x);
    text-align: left;
    width: 100%;
    svg {
      padding-left: v($spacing-scale-2x);
      color: v(color-primary-default);
      font-size: v(icon-size-lg);
    }
    span {
      font-size: v(font-size-scale-base);
      font-weight: v(font-weight-regular);
      color: v(color-primary-default);
      padding-left: v(spacing-scale-half);
    }
  }

  .feedback {
    margin-top: v(spacing-scale-base);
  }

  .upload-list {
    @include list;
    @include item($ellipsis: true);
  }

  @include media-breakpoint-down(xs) {
    .br-button.upload-button {
      width: 100% !important;
      padding: v(spacing-scale-base) v(spacing-scale-2x);
      height: auto;
    }
    .upload-button {
      span {
        font-size: v(font-size-scale-down-01);
      }
    }
  }
}
