.#{$prefix}input {
  position: relative;
  label {
    display: inline-block;
    padding-bottom: v(spacing-scale-half);
  }
  input {
    @include input;
    &[disabled] {
      color: v(color-secondary-06);
      background-color: v(color-secondary-03);
      border: 1px solid v(color-secondary-06);
      ~ button {
        &.icon {
          color: v(color-secondary-06);
          cursor: not-allowed;
        }
      }
      ~ .help {
        display: none;
      }
    }
  }
  // TODO: Remover [1] após revisão do feedback e help
  // 1 - temporário até remover feedback e help de dentro do input
  button {
    &.icon {
      background: transparent;
      border: 0;
      bottom: 0;
      font-size: $input-font-size;
      height: $input-default;
      margin: 0;
      min-width: 0;
      padding: 0 v(spacing-scale-2x);
      position: absolute;
      right: 0;
      width: auto;
      top: 1.5em; // 1
      color: v(color-primary-default);
      outline: none;
      img {
        max-width: 24px;
        min-width: 24px;
        transform: translate(0, -4px);
      }
      &:focus,
      &:hover {
        color: v(color-primary-lighten-01);
      }
    }
  }
  ~ .help {
    font-size: v(font-size-scale-down-01);
  }
  &[valid] {
    input {
      border: 2px solid v(color-success);
    }
    button {
      &.icon {
        color: v(color-success);
      }
    }
  }
  &[invalid] {
    input {
      border: 2px solid v(color-danger);
    }
    button {
      &.icon {
        color: v(color-danger);
      }
    }
  }
  &[filter] {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    label {
      white-space: nowrap;
      margin-right: v(spacing-scale-2x);
    }
    input {
      min-height: $input-small;
    }
  }
}
