@import "~flatpickr/dist/flatpickr.min.css";

// Esconder "setas" do input.
input {
  -webkit-appearance: none;
}

.flatpickr-calendar {
  -webkit-box-shadow: 0 3px 6px rgba($color-secondary-09, 0.16);
  -moz-box-shadow: 0 3px 6px rgba($color-secondary-09, 0.16);
  box-shadow: 0 3px 6px rgba($color-secondary-09, 0.16);
  padding: 0 0 18px 0;
  width: 312px;
  &.noCalendar {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 174px;
    justify-content: center;
    padding: 0;
    width: 240px;
  }
  &.hasTime {
    &.open {
      & > .flatpickr-time {
        align-items: center;
        border: none;
        border-top: 1px solid v(color-secondary-04, #ccc);
        height: 100px;
        justify-content: center;
        line-height: 48px;
        margin: 1em 1em 0 1em;
        max-height: none;
        & div.numInputWrapper {
          display: flex;
          flex: none;
          flex-direction: row;
          justify-content: space-evenly;
          width: 47px;
        }
        & .arrowUp,
        & .arrowDown {
          border: none;
          border-radius: 50%;
          color: v(color-primary-default);
          cursor: pointer;
          height: 25px;
          left: 12px;
          line-height: 25px;
          opacity: 1;
          padding: 0;
          width: 25px;
          &::after {
            border: solid var(--color-primary-lighten-01);
            border-width: 0 3px 3px 0;
            content: '';
            height: 10px;
            left: 7px;
            position: absolute;
            top: 7px;
            transform: rotate(45deg);
            width: 10px;
          }
          @include focus;
          &[focus] {
            background: v(status-highlight-background)
              linear-gradient(v(status-focused-overlay), v(status-focused-overlay));
          }
          @include hover;
          &[hover] {
            background: v(status-hover-background);
          }
        }
        .arrowUp {
          top: -26px;
          &::after {
            top: 9px;
            transform: rotate(225deg);
          }
          @include active;
          &:active {
            color: v(color-secondary-01);
          }
        }
        .arrowDown {
          bottom: -26px;
          top: unset;
          @include active;
          &:active {
            color: v(color-secondary-01);
          }
        }
        & .flatpickr-time-separator {
          height: 50px;
          margin: 0 13px;
        }
        input,
        .flatpickr-am-pm {
          background: v(color-secondary-01);
          border: 1px solid #888;
          border-radius: 6px;
          display: block;
          font-weight: bold;
          height: 40px;
          line-height: 40px;
          min-height: 40px;
          padding: 0;
          width: 47px;
          .numInput.flatpickr-minute {
            margin-left: 13px;
          }
        }
        .flatpickr-am-pm {
          margin-left: 24px;
          @include focus;
          &[focus] {
            background: v(status-highlight-background)
              linear-gradient(v(status-focused-overlay), v(status-focused-overlay));
          }
          @include hover;
          &[hover] {
            background: v(status-hover-background);
          }
          @include active;
          &:active {
            color: v(color-secondary-01);
          }
        }
      }
      &.noCalendar {
        & > .flatpickr-time {
          border-top: none;
          margin: 0;
        }
      }
    }
  }
  &:after,
  &:before {
    display: none;
  }
}
.flatpickr-months {
  padding-top: 16px;
  .flatpickr-month {
    .flatpickr-current-month {
      .flatpickr-monthDropdown-months {
        .flatpickr-monthDropdown-month {
          background-color: transparent;
          color: var(--color-secondary-08);
          outline: none;
          padding: 0;
        }
        border: none;
        color: v(color-primary-default);
        font-size: v(font-size-scale-up-01);
        font-weight: v(font-weight-medium);
        margin-right: 13px;
        &:hover {
          background: transparent;
        }
        &:focus {
          background: v(status-highlight-background)
            linear-gradient(v(status-focused-overlay), v(status-focused-overlay));
        }
      }
      .numInputWrapper {
        margin-left: 13px;
        width: 62px;
        input.numInput.cur-year {
          border: 1px solid v(color-secondary-06);
          border-radius: 6px;
          color: v(color-secondary-08);
          font-size: v(font-size-scale-up-01);
          font-weight: v(font-weight-medium);
          padding: 0 8px;
          &:focus {
            background: v(status-highlight-background)
              linear-gradient(v(status-focused-overlay), v(status-focused-overlay));
          }
        }
        span {
          &.arrowUp,
          &.arrowDown {
            display: none;
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }
  .flatpickr-prev-month,
  .flatpickr-next-month {
    color: rgba(v(rgb-primary-default), 0.9);
    fill: rgba(v(rgb-primary-default), 0.9);
    svg {
      height: 1em;
    }
    &:hover svg {
      fill: v(color-primary-default);
    }
  }
  .flatpickr-prev-month.flatpickr-prev-month {
    left: 8px;
    top: 10px;
  }
  .flatpickr-next-month.flatpickr-next-month {
    right: 8px;
    top: 10px;
  }
}
.flatpickr-weekdays {
  padding: 0 33px;
  .flatpickr-weekday {
    color: v(color-secondary-07);
    font-size: v(font-size-scale-base);
    font-weight: v(font-weight-medium);
  }
}
.dayContainer {
  justify-content: flex-start;
  padding: 0 27px;
  .flatpickr-day {
    align-items: center;
    color: v(color-primary-default);
    cursor: pointer;
    display: flex;
    font-size: v(font-size-scale-base);
    font-weight: v(font-weight-semi-bold);
    height: 24px;
    line-height: 28px;
    justify-content: center;
    margin: 3px 6px;
    max-width: 24px;
    padding: 0 1px;
    width: 24px;
    &.flatpickr-disabled {
      color: v(color-secondary-04);
      font-weight: v(font-weight-regular);
      opacity: 0.45;
    }
    &.today {
      background-color: v(status-highlight-overlay);
      color: v(status-highlight-text);
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      border-radius: 16px;
      border-width: 0;
      &:hover {
        background-color: v(status-highlight-overlay);
        color: v(status-highlight-text);
      }
      &.selected {
        color: v(status-selected-text);
      }
    }
    &.prevMonthDay,
    &.nextMonthDay {
      color: v(color-primary-default);
      font-weight: v(font-weight-medium);
      opacity: 0.6;
    }
    &.inRange,
    &.prevMonthDay.inRange,
    &.nextMonthDay.inRange,
    &.today.inRange,
    &.prevMonthDay.today.inRange,
    &.nextMonthDay.today.inRange,
    &:hover,
    &.prevMonthDay:hover,
    &.nextMonthDay:hover,
    &:focus,
    &.prevMonthDay:focus,
    &.nextMonthDay:focus {
      background: v(status-hover-background);
      border-color: transparent;
    }
    &.selected,
    &.selected.endRange.inRange,
    &.selected.prevMonthDay:hover {
      background: v(status-selected-background);
      border-width: 0;
      color: v(status-selected-text);
      opacity: 1;
    }
    &.selected.endRange.inRange {
      border-radius: 0 50px 50px 0;
    }
    &.inRange,
    &.inRange.today,
    &.prevMonthDay.inRange,
    &.nextMonthDay.inRange,
    &.prevMonthDay.today.inRange,
    &.nextMonthDay.today.inRange {
      -webkit-box-shadow: none;
      -webkit-border-radius: 0;
      background: rgba(v(rgb-primary-lighten-01), 0.5);
      border-radius: 0;
      box-shadow: none;
      color: v(color-secondary-01);
      opacity: 1;
    }
  }
}
