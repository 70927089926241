@mixin input {
  @include focus;
  background: v(color-secondary-01);
  border: $input-border-width solid $input-border-color;
  border-radius: $input-radius;
  display: block;
  min-height: $input-medium;
  padding-left: v(spacing-scale-2x);
  padding-right: v(spacing-scale-5x);
  width: 100%;
  &:focus {
    outline: none;
  }
}
