.#{$prefix}pagination {
  margin: 0 0 v(spacing-scale-3x);
  padding: 0;
  li {
    display: inline-flex;
    list-style: none;
    margin: 0;
  }
  a {
    border-radius: 100px;
    color: v(color-primary-default);
    font-weight: 400;
    justify-content: center;
    min-width: v(spacing-scale-3x);
    padding: 0 v(spacing-scale-base);
    position: relative;
    text-align: center;
    text-decoration: none !important;
    &:hover {
      background-color: v(color-primary-pastel-02);
      text-decoration: none;
    }
  }
  .is-active {
    a {
      background-color: $color-primary-default;
      color: v(color-secondary-01);
    }
  }
  .is-first {
    &::after {
      color: v(color-secondary-04);
      content: '|';
    }
  }
  .is-last {
    &::before {
      color: v(color-secondary-04);
      content: '|';
    }
  }
  .is-disabled {
    a {
      color: v(color-secondary-06);
      cursor: default;
      pointer-events: none;
    }
  }
  // Tamanhos
  &.is-large {
    @include media-breakpoint-up(sm) {
      li {
        font-size: $font-size-base * 1.25;
      }
      a {
        margin: 0 v(spacing-scale-half);
        min-width: calc(#{v(spacing-scale-base)} * 3.75);
      }
      .is-first {
        &::after {
          margin: 0 v(spacing-scale-base);
        }
      }
      .is-last {
        &::before {
          margin: 0 v(spacing-scale-base);
        }
      }
    }
  }
}
