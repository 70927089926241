@mixin button {
  @include button-tokens;
  @include button-base;
  @include button-circle;
  @include button-sizes;
  @include button-emphasis;
  @include button-colors;
  @include button-states;
}

@mixin button-tokens {
  --button-background: var(--color-secondary-01);
  --button-border: transparent;
  --button-color: var(--color-primary-default);
  --button-radius: #{$button-radius};
  --button-font-size: var(--font-size-scale-up-01);
  --button-font-weight: var(--font-weight-semi-bold);
  --button-xsmall: 24px;
  --button-small: 32px;
  --button-medium: 40px;
  --button-large: 48px;
  --button-size: var(--button-medium);
  --button-padding-y: 0;
  --button-padding-x: var(--spacing-scale-5x);
  --button-padding: var(--button-padding-y) var(--button-padding-x);
}

@mixin button-base {
  background-color: $color-secondary-01;
  border: 1px solid transparent;
  border-radius: 8px;
  color: $color-primary-default;
  cursor: pointer;
  display: inline-block;
  font-size: $font-size-scale-up-01;
  font-weight: $font-weight-semi-bold;
  height: 40px;
  padding: 0 $spacing-scale-5x;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  @include media-breakpoint-up(sm) {
    &:not([circle]):not(.circle) {
      width: auto;
    }
  }
  @supports (display: grid) {
    align-items: center;
    background-color: var(--button-background);
    border-color: var(--button-border);
    border-radius: var(--button-radius);
    color: var(--button-color);
    display: inline-flex;
    font-size: var(--button-font-size);
    font-weight: var(--button-font-weight);
    height: var(--button-size);
    justify-content: center;
    padding: var(--button-padding);
  }
}

@mixin button-circle {
  &.circle,
  &[circle],
  &.is-circle {
    border-radius: 50%;
    padding: 0;
    width: 40px;
    @supports (display: grid) {
      width: var(--button-size);
    }
  }
}

@mixin button-sizes {
  @each $name, $size in ("xsmall", 24px), ("small", 32px), ("medium", 40px),
    ("large", 48px)
  {
    &.#{$name},
    &[#{$name}],
    &.is-#{$name} {
      height: $size;
      width: $size;
      @supports (display: grid) {
        --button-size: var(--button-#{$name});
        height: var(--button-size);
        width: var(--button-size);
      }
    }
  }
}

@mixin button-emphasis {
  &.primary,
  &[primary],
  &.is-primary {
    background-color: $color-primary-default;
    color: $color-secondary-01;
    @supports (display: grid) {
      --button-background: var(--status-enabled-text);
      --button-color: var(--color-secondary-01);
      background-color: var(--button-background);
      color: var(--button-color);
    }
  }
  &.secondary,
  &[secondary],
  &.is-secondary {
    border-color: $color-primary-default;
    @supports (display: grid) {
      --button-border: var(--status-enabled-text);
      border-color: var(--button-border);
    }
  }
}

@mixin button-colors {
  @each $name, $state in ("danger", $color-danger), ("success", $color-success),
    ("warning", $color-warning), ("info", $color-info)
  {
    &.#{$name},
    &[#{$name}],
    &.is-#{$name} {
      background-color: $state;
      @supports (display: grid) {
        --button-background: var(--color-#{$name});
        background-color: var(--button-background);
      }
      @if $name != "warning" {
        color: $color-secondary-01;
        @supports (display: grid) {
          --button-color: var(--color-secondary-01);
          color: var(--button-color);
        }
      }
    }
  }
}

@mixin button-states {
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &:not(:disabled):not(.loading) {
    &:focus {
      background-color: $color-secondary-01;
      background-image: linear-gradient(
        rgba($color-support-02, 0.3),
        rgba($color-support-02, 0.3)
      );
      color: $color-primary-default;
      @supports (display: grid) {
        --button-background: var(--color-secondary-01);
        --button-color: var(--color-primary-default);
        background-color: var(--button-background);
        background-image: linear-gradient(
          rgba(var(--rgb-support-02), 0.3),
          rgba(var(--rgb-support-02), 0.3)
        );
        color: var(--button-color);
      }
    }
    &:hover {
      background-image: none;
      background-color: $color-primary-pastel-02;
      color: $color-primary-default;
      @supports (display: grid) {
        --button-background: var(--status-hover-background);
        --button-color: var(--color-primary-default);
        background-color: var(--button-background);
        color: var(--button-color);
      }
    }
    &:active {
      background-color: $color-primary-darken-02;
      color: $color-secondary-01;
      @supports (display: grid) {
        --button-background: var(--status-pressed-background);
        --button-color: var(--status-pressed-text);
        background-color: var(--button-background);
        color: var(--button-color);
      }
    }
  }
  &.loading,
  &[loading],
  &.is-loading {
    &::after {
      border-style: solid;
      border-color: $color-primary-default $color-primary-default transparent;
      @supports (display: grid) {
        border-color: var(--button-color) var(--button-color) transparent;
      }
    }
    &.primary,
    &.danger,
    &.success,
    &.info {
      &::after {
        border-color: $color-secondary-01 $color-secondary-01 transparent;
        @supports (display: grid) {
          border-color: var(--button-color) var(--button-color) transparent;
        }
      }
    }
  }
}
