.#{$prefix}header {
  min-width: 320px;
  $logo-mobile-width: 80px;
  $logo-mobile-height: 40px;
  $logo-desktop-width: 120px;
  $logo-desktop-height: 48px;
  $logo-compact: 60px;
  $logo-compact-height: 32px;
  box-shadow: 0 3px 6px rgba(var(--rgb-secondary-09), 0.16);
  background: v(color-secondary-01);
  position: relative;
  display: flex;

  .flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    padding: v(spacing-scale-2x) 0;
    z-index: 1;
  }

  &.sticky {
    top: 0;
    left: 0;
    width: 100vw;
    margin-top: 0;
    position: fixed;
    z-index: 1;
    .flex-container {
      width: 100%;
      flex-wrap: wrap;
    }
    & + div {
      margin-top: 96px;
      position: relative;
      z-index: auto;
    }
    .menu {
      display: flex !important;
    }
  }

  // Logo (opcional)
  .logo {
    order: 1;
    width: min-content;
    flex-grow: 2;
    align-items: center;
    display: flex;
    img {
      max-width: $logo-desktop-width;
      max-height: $logo-desktop-height;
    }

    // Assinatura (opcional)
    .sign {
      margin-left: v(spacing-scale-2x);
      font-size: v(font-size-scale-base);
      color: v(color-secondary-05);
      display: -webkit-box;
      line-height: 1.3em;
      height: auto;
      max-height: 2.6em;
      width: fit-content;
      overflow: hidden;
      white-space: initial;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  // Botões de ação
  .actions {
    max-width: 60%;
    height: 40px;
    order: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 3;
    position: relative;

    // Links (opcional)
    .links {
      order: 1;
      border-top: 0;
      font-size: v(font-size-scale-down-01);
      padding: 0;
      position: relative;
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;
      scrollbar-width: thin;
      display: flex;
      align-items: center;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px v(color-secondary-02);
        background-color: v(color-secondary-02);
      }

      &::-webkit-scrollbar {
        height: 6px;
        background-color: v(color-secondary-02);
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb {
        background-color: v(color-secondary-04);
        border: 1px solid v(color-secondary-01);
      }
      & > button {
        display: none;
      }
      ul {
        margin-top: v(spacing-scale-2x);
        li {
          padding: v(spacing-scale-base);
          a {
            white-space: nowrap;
          }
        }
      }
      a {
        font-size: v(font-size-scale-down-01);
      }
    }

    // Funcionalidades (opcional)
    .functions {
      order: 2;
      position: relative;
      display: flex;

      ul {
        white-space: nowrap;
        .text {
          display: none;
        }
      }
      & > button {
        display: none;
      }
    }

    // Listas de links e botões
    .links,
    .functions {
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        text-align: center;
        li {
          display: inline;
          a {
            text-decoration: none;
            display: inline;
          }
          &.title {
            display: none;
          }
        }
      }
      & > button {
        width: 32px;
        text-align: center;
      }
    }

    .search-btn {
      order: 3;
      display: none;
    }

    // Acesso gov.br (não logado)
    .login {
      order: 4;
      .#{$prefix}button {
        @include hover;
        @include focus;
        @include active;
        width: auto;
        padding: 0 v(spacing-scale-base);
        white-space: nowrap;
        background: v(color-secondary-02);
        margin-left: v(spacing-scale-base);
        span {
          margin-left: 0 !important;
        }
      }
    }

    // Avatar (logado)
    .avatar {
      order: 4;
      align-items: center;
      padding: 0 v(spacing-scale-base) 0 v(spacing-scale-3x);
      margin-top: v(spacing-scale-base);
      display: flex;
      flex-wrap: nowrap;
      cursor: pointer;
      &[hidden] {
        display: none;
      }
    }
    .popmenu {
      display: none;
    }
  }

  // Menu, título e subtítulo
  .menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 3;
    margin-top: v(spacing-scale-base);
    order: 3;
    width: calc(100% - 300px);
    // Título do Cabeçalho
    .title {
      font-size: v(font-size-scale-up-03);
      color: v(color-secondary-08);
      display: -webkit-box;
      line-height: 1.3em;
      height: auto;
      margin: 0;
      max-height: 2.6em;
      width: calc(100% - 40px);
      overflow: hidden;
      white-space: initial;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    // Subítulo do Cabeçalho (opcional)
    .subtitle {
      font-size: v(font-size-scale-base);
      margin-left: v(spacing-scale-4x);
      color: v(color-secondary-05);
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }

    & > button {
      margin-top: v(spacing-scale-half);
    }
  }

  // Busca
  .search {
    order: 4;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 56px;
    width: 300px;
    .#{$prefix}input {
      width: 100%;
      margin-top: v(spacing-scale-base);
      input {
        background: v(color-secondary-02);
        border: 0;
      }
      button.icon {
        top: auto;
        .icon {
          border-left: 1px solid v(color-primary-lighten-01);
          padding-left: v(spacing-scale-base);
          height: 24px;
          line-height: 20px;
          top: 12px;
        }
      }
    }
    .search-close {
      max-width: 0;
      overflow: hidden;
    }
    &[active] {
      order: 4;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      .search-close {
        max-width: 32px;
        margin-bottom: v(spacing-scale-half);
        visibility: visible;
        transition: max-width 1s ease-in-out;
      }
    }
  }

  // Espaçadores
  &:not([no-links]) .links + .functions {
    &::before {
      content: "";
      border-left: 1px solid v(color-secondary-03);
      height: v(spacing-scale-2x);
      margin-top: v(spacing-scale-base);
      margin-right: v(spacing-scale-base);
      margin-left: v(spacing-scale-half);
    }
  }
  &:not([no-functions]) .functions + .search-btn,
  &:not([no-links]) .links + .search-btn {
    &::before {
      content: "";
      border-left: 1px solid v(color-secondary-03);
      height: v(spacing-scale-2x);
      margin-top: v(spacing-scale-base);
      margin-left: v(spacing-scale-base);
      margin-right: v(spacing-scale-half);
    }
  }

  &[compact],
  &.sticky[compact] {
    .flex-container {
      flex-wrap: nowrap;
      padding-top: v(spacing-scale-4x);
    }
    .logo {
      position: absolute;
      left: v(spacing-scale-base);
      margin-top: calc(#{v(spacing-scale-2xh)} * -1);
      img {
        max-width: $logo-compact;
        max-height: $logo-compact-height;
      }
      .sign {
        display: none;
      }
    }
    .menu {
      order: 2;
      margin-top: 0;
      .title {
        font-size: v(font-size-scale-up-02);
      }
      .subtitle {
        font-size: v(font-size-scale-down-01);
      }
    }
    .actions {
      order: 3;
      margin-top: 0;
      .search-btn {
        display: flex;
        flex-wrap: nowrap;
      }
    }
    .search {
      display: none;
    }
    .popmenu[active] {
      position: absolute;
      display: unset !important;
      left: unset;
      right: 0;
      top: 0;
      max-height: -webkit-fill-available;
      order: 0;
      width: 100%;
      z-index: 999;
      ul {
        margin-top: 0;
      }
      .search {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 100%;
        min-width: 320px;
        left: 0;
        max-height: 100%;
        background: v(color-secondary-01);
        padding: v(spacing-scale-base) v(spacing-scale-base)
          v(spacing-scale-base) v(spacing-scale-2x);
        z-index: 99;
        .#{$prefix}input {
          margin-top: 0;
          width: 100%;
          display: flex;
          input {
            padding-top: 0;
            width: 100%;
          }
        }
        .search-close {
          display: unset;
          max-width: 32px;
          margin: 0 v(spacing-scale-base);
        }
      }
    }
  }

  /******************************* */
  // Variações de atributos

  &[no-sign] {
    .logo {
      max-width: 140px;
      .sign {
        display: none;
      }
    }
    .links {
      max-width: min-content;
    }
  }

  &[compact][no-logo] {
    .flex-container {
      padding-top: v(spacing-scale-2x);
    }
    .logo {
      display: none;
    }
  }

  &[no-subtitle] {
    .subtitle {
      display: none;
    }
  }

  &[no-links] {
    .actions {
      max-width: fit-content;
      .links {
        display: none;
      }
    }
    .logo {
      width: min-content;
      .sign {
        width: fit-content;
      }
    }
    &[compact] {
      .menu {
        width: 75%;
      }
      .actions {
        width: 25%;
      }
    }
  }
  &[no-functions] {
    .actions {
      .functions {
        display: none;
      }
    }
  }

  &[no-login] {
    .login,
    .avatar {
      display: none;
    }
  }

  &[no-search] {
    .search {
      display: none;
    }
    .search-btn {
      display: none !important;
    }
  }
  &:not([compact]) {
    &[no-links][no-functions],
    &[no-links][no-login] {
      .actions {
        min-width: 20%;
      }
      &[no-sign] {
        .actions {
          min-width: 60%;
        }
      }
    }
  }

  /********************************* */
  // Breakpoint Grid 8 colunas (tablet)
  @include media-breakpoint-down(sm) {
    .flex-container {
      padding: v(spacing-scale-2x) 0;
    }

    .logo {
      padding-left: v(spacing-scale-base);
    }

    .actions {
      max-width: fit-content;
      max-width: -moz-fit-content;
      flex-grow: 2;
      .links {
        overflow: visible;
        & > button {
          display: unset !important;
        }
        ul {
          display: none;
          width: auto;
        }
      }
      .functions {
        & > button {
          display: unset !important;
        }
        ul {
          display: none;
          li {
            button {
              margin-left: calc(#{v(spacing-scale-base)} * -1);
              .svg-inline--fa,
              .fas {
                padding-left: v(spacing-scale-half);
              }
              .text {
                display: inline;
                margin-left: v(spacing-scale-base);
              }
            }
          }
        }
      }
      .search-btn {
        display: flex;
        flex-wrap: nowrap;
        position: unset;
        &[active] {
          position: absolute;
          & > button {
            display: none;
          }
        }
      }
    }
    .menu {
      .title {
        font-size: v(font-size-scale-up-02);
      }
      .subtitle {
        font-size: v(font-size-scale-down-01);
      }
    }
    .search {
      display: none;
    }
    .popmenu[active] {
      background: rgba(var(--rgb-secondary-01), 0.5);
      position: absolute;
      display: unset !important;
      left: unset;
      right: 0;
      top: 0;
      order: 0;
      width: 100%;
      z-index: 999;
      ul {
        display: unset !important;
        position: absolute;
        width: auto;
        list-style-type: none;
        height: auto;
        top: 32px;
        right: 0;
        background: v(color-secondary-01);
        padding: v(spacing-scale-baseh) v(spacing-scale-4x)
          v(spacing-scale-base) v(spacing-scale-2x);
        box-shadow: 0 0px 7px rgba(var(--rgb-secondary-09), 0.16);
        z-index: 99;
        li {
          display: block;
          margin: v(spacing-scale-base);
          text-align: left;
          padding: 0;
          a {
            text-decoration: none;
            display: block;
          }
          &.title {
            padding: 0;
            margin: 0;
            display: unset;
            font-size: v(font-size-scale-down-01);
            color: v(color-secondary-08);
            font-weight: v(font-weight-bold);
            text-transform: uppercase;
            margin-bottom: v(spacing-scale-2x);
          }
        }
      }
      .search {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        max-height: 60px;
        margin-top: 5.3em;
        left: 0;
        background: v(color-secondary-01);
        padding: 0 v(spacing-scale-base) v(spacing-scale-base)
          v(spacing-scale-2x);
        z-index: 99;
        .#{$prefix}input {
          margin-top: 0;
          width: 100%;
          display: flex;
          input {
            padding-top: 0;
            width: 100%;
          }
        }
        .search-close {
          display: unset;
          max-width: 32px;
          margin: 0 v(spacing-scale-base);
        }
      }
    }

    [hidden] {
      display: none;
    }

    &[compact] {
      .logo {
        left: v(spacing-scale-base);
        padding-left: 0;
      }
      .menu {
        .title {
          font-size: v(font-size-scale-up-01);
        }
        .subtitle {
          font-size: v(font-size-scale-down-01);
        }
      }
      .popmenu[active] {
        display: flex;
        .search {
          min-height: 100%;
          top: 0;
          margin-top: 0;
          max-height: 100%;
        }
        ul {
          margin-top: 0;
        }
      }
    }
  }

  /********************************* */
  // Breakpoint Grid 4 colunas (mobile)
  @include media-breakpoint-down(xs) {
    .flex-container {
      padding: v(spacing-scale-2x) 0;
    }
    .menu {
      width: 100%;
      .title {
        font-size: v(font-size-scale-up-base);
      }
      .subtitle {
        display: none;
      }
      & > button {
        margin-top: calc(#{v(spacing-scale-base)} * -1);
      }
    }
    .logo {
      width: $logo-mobile-width;
      position: absolute;
      .sign {
        display: none;
      }
      img {
        max-width: $logo-mobile-width;
        max-height: $logo-mobile-height;
      }
    }
    .search {
      height: 40px;
      align-items: center;
    }
    .actions {
      display: flex;
      position: relative;
      flex-wrap: nowrap;
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      .links,
      .functions,
      .search-btn {
        display: flex;
        flex-wrap: nowrap;
        position: unset;
        .popmenu {
          position: absolute;
          width: 100%;
          left: 0;
          top: v(spacing-scale-base);
          ul {
            width: 100%;
          }
        }
      }
    }
    &:not([compact]) .popmenu[active] {
      .search {
        margin-top: 4.6em;
        height: 40px;
        max-height: 40px;
        .br-input {
          input {
            height: 32px;
            line-height: 32px;
            min-height: 32px;
          }
          .icon {
            height: 32px;
            .icon {
              top: 6px;
              height: 20px;
            }
          }
        }
      }
    }
    &[compact],
    &.sticky[compact],
    &[compact][no-logo] {
      .flex-container {
        flex-wrap: wrap;
        padding-top: v(spacing-scale-2x);
        padding-bottom: v(spacing-scale-base);
      }
      .logo {
        left: v(spacing-scale-base);
        order: 1;
        position: absolute;
        margin-top: 0;
        display: flex;
        align-items: center;
        width: $logo-mobile-width;
        img {
          max-width: $logo-mobile-width;
          max-height: $logo-mobile-height;
        }
      }
      .menu {
        order: 3;
        min-width: 100%;
        padding: v(spacing-scale-base) 0;
        .title {
          font-size: v(font-size-scale-up-base);
        }
      }
      .actions {
        order: 2;
        align-self: flex-end;
        min-width: 100%;
        max-width: 100%;
        ul {
          white-space: nowrap;
        }
      }
      .popmenu[active] {
        ul {
          margin-top: v(spacing-scale-base);
        }
      }
    }
  }
}
