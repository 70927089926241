// Alinhamento Vertical
$spacing-vertical-top: top;
$spacing-vertical-center: center;
$spacing-vertical-bottom: bottom;

// Alinhamento Horizontal
$spacing-horizontal-left: left;
$spacing-horizontal-center: center;
$spacing-horizontal-right: right;

// Escala
$spacing-scale-default: 0;

$spacing-scale-base: 8px;
$spacing-scale-2x: $spacing-scale-base * 2;
$spacing-scale-3x: $spacing-scale-base * 3;
$spacing-scale-4x: $spacing-scale-base * 4;
$spacing-scale-5x: $spacing-scale-base * 5;
$spacing-scale-6x: $spacing-scale-base * 6;
$spacing-scale-7x: $spacing-scale-base * 7;
$spacing-scale-8x: $spacing-scale-base * 8;
$spacing-scale-9x: $spacing-scale-base * 9;
$spacing-scale-10x: $spacing-scale-base * 10;

$spacing-scale-half: $spacing-scale-base * 0.5;
$spacing-scale-baseh: $spacing-scale-base * 1.5;
$spacing-scale-2xh: $spacing-scale-base * 2.5;
$spacing-scale-3xh: $spacing-scale-base * 3.5;
$spacing-scale-4xh: $spacing-scale-base * 4.5;
$spacing-scale-5xh: $spacing-scale-base * 5.5;

// Compatibilidade com Bootstrap
$spacer: $spacing-scale-base;
$spacers: (
  0: $spacing-scale-default,
  1: $spacing-scale-base,
  2: $spacing-scale-baseh,
  3: $spacing-scale-2x,
  4: $spacing-scale-3x,
  5: $spacing-scale-5x,
  half: $spacing-scale-half,
  base: $spacing-scale-base,
  baseh: $spacing-scale-baseh,
  2x: $spacing-scale-2x,
  2xh: $spacing-scale-2xh,
  3x: $spacing-scale-3x,
  3xh: $spacing-scale-3xh,
  4x: $spacing-scale-4x,
  4xh: $spacing-scale-4xh,
  5x: $spacing-scale-5x,
  5xh: $spacing-scale-5xh,
  6x: $spacing-scale-6x,
  7x: $spacing-scale-7x,
  8x: $spacing-scale-8x,
  9x: $spacing-scale-9x,
  10x: $spacing-scale-10x,
);

$tokens-alinhamento: (
  spacing-vertical-top: $spacing-vertical-top,
  spacing-vertical-center: $spacing-vertical-center,
  spacing-vertical-bottom: $spacing-vertical-bottom,
  spacing-horizontal-left: $spacing-horizontal-left,
  spacing-horizontal-center: $spacing-horizontal-center,
  spacing-horizontal-right: $spacing-horizontal-right,
);

$tokens-spacing: (
  spacing-scale-default: $spacing-scale-default,
  spacing-scale-base: $spacing-scale-base,
  spacing-scale-2x: $spacing-scale-2x,
  spacing-scale-3x: $spacing-scale-3x,
  spacing-scale-4x: $spacing-scale-4x,
  spacing-scale-5x: $spacing-scale-5x,
  spacing-scale-6x: $spacing-scale-6x,
  spacing-scale-7x: $spacing-scale-7x,
  spacing-scale-8x: $spacing-scale-8x,
  spacing-scale-9x: $spacing-scale-9x,
  spacing-scale-10x: $spacing-scale-10x,
  spacing-scale-half: $spacing-scale-half,
  spacing-scale-baseh: $spacing-scale-baseh,
  spacing-scale-2xh: $spacing-scale-2xh,
  spacing-scale-3xh: $spacing-scale-3xh,
  spacing-scale-4xh: $spacing-scale-4xh,
  spacing-scale-5xh: $spacing-scale-5xh,
);
