// Texto padrão
body {
  --font-size: var(--font-size-scale-base);
  --text-color: var(--color-secondary-08);
  --link-color: var(--color-primary-default);
  --bg-color: var(--color-secondary-01);
  --font-weight: var(--font-weight-regular);
  --line-height: var(--font-line-height-medium);
  color: v(text-color);
  font-family: v(font-family-base);
  font-size: v(font-size);
  font-weight: v(font-weight);
  line-height: v(line-height);
}

// Parágrafos
p {
  margin-bottom: v(spacing-scale-2x);
  margin-top: 0;
}

// Links
a {
  color: v(color-primary-default);
  text-decoration: none;
}
.page-content {
  a {
    @include hover;
    @include focus;
  }
}

// Botões
button {
  cursor: pointer;
}

// Imagens
img {
  max-width: 100%;
  vertical-align: middle;
}

// Divisores
hr {
  background-color: v(color-secondary-04);
  border: 0;
  color: inherit;
  height: 1px;
  margin: v(spacing-scale-2x) 0;
}

// Títulos
h1,
.h1 {
  font-size: v(font-size-scale-up-06);
  font-weight: v(font-weight-regular);
}
h1 {
  line-height: v(font-line-height-low);
  margin-bottom: v(spacing-scale-4x);
  margin-top: 0;
}
h2,
.h2 {
  font-size: v(font-size-scale-up-05);
  font-weight: v(font-weight-regular);
}
h2 {
  line-height: v(font-line-height-low);
  margin-bottom: v(spacing-scale-2xh);
  margin-top: v(spacing-scale-3xh);
}
h3,
.h3 {
  font-size: v(font-size-scale-up-04);
  font-weight: v(font-weight-regular);
}
h3 {
  line-height: v(font-line-height-low);
  margin-bottom: v(spacing-scale-2xh);
  margin-top: v(spacing-scale-3xh);
}
h4,
.h4 {
  font-size: v(font-size-scale-up-03);
  font-weight: v(font-weight-regular);
}
h4 {
  line-height: v(font-line-height-low);
  margin-bottom: v(spacing-scale-2xh);
  margin-top: v(spacing-scale-3xh);
}
h5,
.h5 {
  font-size: v(font-size-scale-up-02);
  font-weight: v(font-weight-semi-bold);
}
h5 {
  line-height: v(font-line-height-low);
  margin-bottom: v(spacing-scale-2x);
  margin-top: v(spacing-scale-3xh);
}
h6,
.h6 {
  font-size: v(font-size-scale-up-01);
  font-weight: v(font-weight-semi-bold);
}
h6 {
  line-height: v(font-line-height-low);
  margin-bottom: v(spacing-scale-2x);
  margin-top: v(spacing-scale-3xh);
}

// Listas
ul,
ol {
  margin: v(spacing-scale-2x) 0;
  padding: 0 v(spacing-scale-6x);
}

// Blockquotes
blockquote {
  background-color: v(color-secondary-02);
  border-left: 4px solid $color-highlight;
  font-style: italic;
  margin: v(spacing-scale-2x) 0;
  padding: v(spacing-scale-2x);
  position: relative;
  > *:last-child {
    margin-bottom: 0;
  }
}

// Tabela padrão
/* stylelint-disable no-descending-specificity */
table {
  border-collapse: collapse;
  width: 100%;
  tr {
    height: 56px;
  }
  th,
  td {
    padding: v(spacing-scale-base) v(spacing-scale-2x);
    &:not([align]) {
      text-align: left;
    }
  }
  thead {
    th {
      background-color: v(color-secondary-03);
      color: v(color-primary-darken-01);
    }
  }
  tbody {
    tr {
      @include hover;
    }
    th,
    td {
      @include media-breakpoint-up(sm) {
        border-bottom: 1px solid v(color-primary-pastel-01);
      }
    }
  }
}
/* stylelint-enable no-descending-specificity */

// Formulários
label,
.label {
  font-size: v(font-size-scale-base);
  font-weight: v(font-weight-semi-bold);
  line-height: v(font-line-height-medium);
}
fieldset legend,
.fieldset {
  font-size: v(font-size-scale-up-02);
  font-weight: v(font-weight-regular);
  line-height: v(font-line-height-low);
  margin-bottom: v(spacing-scale-half);
}
::placeholder,
.placeholder {
  font-size: v(font-size-scale-base);
  font-style: italic;
  font-weight: v(font-weight-regular);
  opacity: 1;
}
input,
textarea,
.input {
  font-size: v(font-size-scale-up-01);
  font-weight: v(font-weight-medium);
  color: v(color-secondary-08);
}

// Help
.help {
  font-size: v(font-size-scale-base);
}

// Exemplo de código
code {
  background: v(status-highlight-background)
    linear-gradient(v(status-highlight-overlay), v(status-highlight-overlay));
  border-radius: 4px;
  color: v(status-highlight-text);
  padding: 0 v(spacing-scale-half);
}
pre {
  margin: v(spacing-scale-2x) 0 v(spacing-scale-4x);
  padding: v(spacing-scale-2x);
  code {
    padding: 0;
  }
}
