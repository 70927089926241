$br-modal-box-shadow-color: rgba(0, 0, 0, 0.16);

.#{$prefix}modal {
  box-shadow: 0 3px 6px $br-modal-box-shadow-color;
  padding: v(spacing-scale-2x);
  &-header {
    margin-bottom: v(spacing-scale-3xh);
    position: relative;
    .close {
      background: transparent;
      border: 0;
      color: v(color-primary-default);
      font-weight: v(font-weight-medium);
      padding: 0;
      position: absolute;
      right: 0;
      top: calc(#{v(spacing-scale-half)} * -1);
    }
  }
  &-title {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    font-size: v(font-size-scale-up-01);
    font-weight: v($font-weight-bold);
    margin: 0;
    max-height: v(font-size-scale-up-07);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;

    &.is-inline {
      -webkit-line-clamp: 1;
      max-height: $h3-font-size * 2;
    }
  }
  &-body {
    margin: 0 0 v(spacing-scale-5x);
  }
  &-footer {
    display: flex;
    flex-wrap: wrap;
  }
  .#{$prefix}card {
    border: 0;
    font-size: v(font-size-scale-base);
    height: 216px;
    margin-left: calc(#{v(spacing-scale-2x)} * -1);
    margin-right: calc(#{v(spacing-scale-2x)} * -1);
    overflow: auto;
    padding: 0 v(spacing-scale-2x);
    resize: none;
    width: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: v(color-secondary-03);
    }
    &::-webkit-scrollbar-thumb {
      background: v(color-secondary-06);
    }
    &:focus,
    &:active {
      outline: none;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: v(color-secondary-07);
      }
    }
  }

  &.is-scroll {
    .#{$prefix}card {
      box-shadow: inset 0 -4px 3px rgba(v(rgb-secondary-09), 0.16),
        inset 0 4px 3px rgba(v(rgb-secondary-09), 0.16);
      padding: v(spacing-scale-2x);
    }
  }
}

@include media-breakpoint-up(sm) {
  .#{$prefix}modal {
    width: auto;
    &.is-xsmall {
      max-width: 220px;
    }
    &.is-small {
      max-width: 300px;
    }
    &.is-medium {
      max-width: 500px;
    }
    &.is-large,
    &.is-scroll {
      max-width: 640px;
    }
  }
}
