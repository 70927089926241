.#{$prefix}notification[notification] {
  background: v(color-secondary-01) !important;
  color: v(color-secondary-09);
  max-width: 360px;
  min-width: 352px;
  padding: 0;
  max-height: 0;
  margin-left: -10px;
  position: fixed !important;
  top: 0 !important;

  &[data-show] {
    max-height: 100vh;
  }
  .fas,
  .svg-inline--fa {
    --icon-size: var(--icon-size-sm);
  }

  .arrow {
    border-color: v(color-warning);
  }

  &[data-popper-placement^='top'] {
    border-bottom: 2px solid v(color-warning);
    margin-bottom: 2px !important;
    & > .arrow {
      bottom: -10px;
    }
  }

  &[data-popper-placement^='bottom'] {
    border-top: 2px solid v(color-warning);
    margin-top: 2px !important;
    & > .arrow {
      top: -10px;
      margin-left: v(spacing-scale-2x) !important;
    }
  }

  &[data-popper-placement^='left'] {
    border-right: 2px solid v(color-warning);
    margin-right: 2px !important;
    & > .arrow {
      right: -10px;
    }
  }

  &[data-popper-placement^='right'] {
    border-left: 2px solid v(color-warning);
    margin-left: 2px !important;
    & > .arrow {
      left: -10px;
    }
  }

  .notification-header {
    padding: 1.2em 1.5em 1em 1.5em;
    border-bottom: 1px solid v(color-secondary-04);
    padding-bottom: v(font-size-scale-down-03);
    width: 100%;
    &,
    .user-name {
      font-size: v(font-size-scale-base);
      font-weight: v(font-weight-bold);
      color: v(color-secondary-09);
    }
    .user-email {
      font-size: v(font-size-scale-down-01);
      font-weight: v(font-weight-regular);
      color: v(color-primary-default);
    }
  }

  .notification-body {
    padding: 0;
    margin-bottom: 0.5em;
    width: 100%;
    .#{$prefix}tabs {
      .tab-nav {
        max-width: 100%;
        overflow-x: auto;
        scrollbar-width: thin;
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px v(color-secondary-02);
          background-color: v(color-secondary-02);
        }

        &::-webkit-scrollbar {
          height: 8px;
          background-color: v(color-secondary-02);
          cursor: pointer;
        }

        &::-webkit-scrollbar-thumb {
          background-color: v(color-secondary-04);
          border: 1px solid v(color-secondary-01);
        }
        ul {
          margin: 0;
          padding: 0 0.5em 0 1em;
          .tab-item {
            padding: 0;
            button {
              margin: 0;
              border-bottom: 2px solid transparent;
              width: fit-content;
              padding: 1em 1em 1em 2.2em;
              .name {
                font-size: v(font-size-scale-down-01);
              }
              .svg-inline--fa,
              .fas {
                font-size: v(font-size-scale-base);
                position: absolute;
                left: 0.4em !important;
                margin-top: 0.3em;
              }
            }
            &.is-active {
              button {
                border-bottom: 2px solid v(color-primary-default);
              }
            }
          }
        }
      }
      .tab-panel {
        font-size: v(font-size-scale-down-01);
        padding: 0.5em 1.5em 0.5em 1.5em;
        max-height: calc(100vh - 330px);
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px v(color-secondary-02);
          background-color: v(color-secondary-02);
        }

        &::-webkit-scrollbar {
          width: 8px;
          background-color: v(color-secondary-02);
          cursor: pointer;
        }

        &::-webkit-scrollbar-thumb {
          background-color: v(color-secondary-04);
          border: 1px solid v(color-secondary-01);
        }
      }
    }
    .#{$prefix}divider {
      margin: 0.5em 0;
    }
    .#{$prefix}list {
      margin-left: -1.5em;
      margin-right: -1.5em;
      .header {
        padding: 0 0 0 v(spacing-scale-2x);
        .actions {
          .#{$prefix}button {
            &[circle][small] {
              vertical-align: middle;
              line-height: 48px;
              border: transparent;
              .svg-inline--fa,
              .fas {
                font-size: v(font-size-scale-base);
                vertical-align: 0.5em;
              }
              &:focus {
                color: v(color-primary-default);
                outline: 0;
              }
              &:hover {
                color: v(color-primary-default);
              }
              &:active {
                color: v(color-secondary-01);
              }
            }
          }
        }
      }
      .item {
        height: auto;
        max-height: 20em;
        position: relative;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: v(spacing-scale-base) v(spacing-scale-half) v(spacing-scale-base)
          v(spacing-scale-4xh);
        font-size: v(font-size-scale-down-02);

        &[hidden] {
          max-height: 0;
          padding: 0 !important;
          color: transparent !important;
          overflow: hidden;
          visibility: hidden;
          transition: color 100ms linear, max-height 300ms linear, visibility 200ms linear;
        }
        .icon {
          position: absolute;
          margin-left: calc(#{v(spacing-scale-3x)} * -1);
          margin-top: 2px;
          .svg-inline--fa,
          .fas {
            font-size: v(font-size-scale-down-01);
          }
        }
        .#{$prefix}badge {
          position: absolute;
          .icon {
            margin-left: calc(#{v(spacing-scale-half)} * -1);
            margin-top: v(spacing-scale-half);
            width: 8px;
            height: 8px;
          }
        }
        .#{$prefix}button {
          &[circle][small] {
            border: transparent;
          }
        }
        .title {
          font-size: v(font-size-scale-down-01);
          font-weight: v(font-weight-bold);
          padding: 0 v(spacing-scale-4x) 0 0;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .date {
          display: initial;
          font-size: v(font-size-scale-down-02);
          font-weight: v(font-weight-medium);
        }
        .content {
          padding: 0.5em 0;
          flex: auto;
        }
        .subtitle {
          display: unset !important;
          font-weight: v(font-weight-medium);
          font-size: v(font-size-scale-down-01);
          width: 100%;
          overflow: hidden;
          padding: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .text {
          font-size: v(font-size-scale-down-02);
          font-weight: v(font-weight-medium);
          width: 100%;
          height: auto;
          overflow: hidden;
          padding: 0;
          white-space: initial;
          text-overflow: unset;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 0;
        }
        .contextual-btn {
          visibility: hidden;
          position: absolute;
          right: 10px;
          top: 10px;
        }
        &:hover .contextual-btn {
          visibility: unset;
        }
        .contextual-menu {
          position: absolute;
          visibility: hidden;
          max-height: 0;
          max-width: 0;
          overflow: hidden;
          right: 16px;
          top: 32px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          background: v(color-secondary-01);
          color: v(color-secondary-09);
          transition: all 500ms ease-in-out;
          &[show] {
            max-height: 100%;
            max-width: 100%;
            visibility: unset;
          }
          div,
          .action {
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: v(spacing-scale-base) v(spacing-scale-2x);
            border-bottom: 1px solid v(color-secondary-04);
            &:hover {
              background: v(status-hover-background);
            }
          }
        }
      }
    }
  }

  .notification-footer {
    padding: 1em 1.5em 1em 1.5em;
    border-top: 1px solid v(color-secondary-04);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
  }

  &[mini] {
    .notification-body {
      .#{$prefix}tabs {
        .tab-nav {
          ul {
            .tab-item {
              margin-right: v(spacing-scale-base);
              button {
                width: 28px;
                overflow: hidden;
                .name {
                  visibility: hidden;
                }
                .svg-inline--fa,
                .fas {
                  visibility: visible;
                  left: 1em !important;
                }
              }
              &:last-child {
                padding-right: v(spacing-scale-4x);
              }
            }
          }
        }
      }
    }
    .close {
      position: absolute;
      top: 0px;
      right: -1px;
      cursor: pointer;
      height: 42px;
      width: 32px;
      background: v(color-secondary-01);
    }
  }

  @include media-breakpoint-down(sm) {
    max-width: 240px;
    min-width: 240px;
    .notification-header {
      display: none;
    }

    .notification-body {
      .#{$prefix}tabs {
        .tab-nav {
          ul {
            .tab-item {
              margin-right: v(spacing-scale-base);
              button {
                width: 32px;
                overflow: hidden;
                .name {
                  visibility: hidden;
                }
                .svg-inline--fa,
                .fas {
                  visibility: visible;
                  left: 0.9em !important;
                }
              }
              &:last-child {
                padding-right: v(spacing-scale-4x);
              }
            }
          }
        }
        .tab-panel {
          max-height: calc(100vh - 276px);
        }
      }
      .#{$prefix}list {
        .header {
          .actions {
            .br-button {
              margin: 0 v(spacing-scale-half);
            }
            .mx-3 {
              margin-left: 0 !important;
              margin-right: 0 !important;
            }
          }
        }
      }
    }
    .close {
      position: absolute;
      top: 0px;
      right: -1px;
      cursor: pointer;
      height: 48px;
      width: 32px;
      background: v(color-secondary-01);
      .svg-inline--fa,
      .fas {
        font-size: v(font-size-scale-base);
      }
    }
  }
}
