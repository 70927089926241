.#{$prefix}tag {
  align-items: center;
  background: v(color-secondary-01);
  border: 1px solid v(color-primary-default);
  border-radius: 16px;
  color: v(color-primary-default);
  display: inline-flex;
  font-size: v(font-size-scale-down-01);
  font-weight: v(font-weight-semi-bold);
  height: 20px;
  justify-content: center;
  margin: 10px;
  padding: 0 10px;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img,
  .content img {
    max-height: 9px;
    max-width: 12px;
  }

  .content [class*='fa-'],
  span [class*='fa-'] {
    text-align: center;
    color: v(color-primary-default);
    font-size: 100%;
    margin-right: 10px;
    width: 12px;
  }

  &:hover,
  &:focus {
    background-color: v(color-primary-pastel-01);
    outline: none;
  }

  &:active,
  &.active {
    background-color: v(color-primary-default);
    color: v(color-secondary-01);

    .content [class*='fa-'],
    span [class*='fa-'] {
      color: v(color-secondary-01);
    }
    &.tag-input-close .close button {
      color: v(color-secondary-01);
    }
  }

  &.disabled {
    background-color: v(color-primary-pastel-01);
    opacity: 0.45;
    &:active,
    &:hover,
    &:focus {
      background: v(color-primary-pastel-01);
      color: v(color-primary-default);
      opacity: 0.45;

      .content [class*='fa-'],
      span [class*='fa-'] {
        color: v(color-primary-default);
      }
    }

    &.tag-input-close .close button {
      color: v(color-support-03);
    }
  }
  &.tag-input-close {
    .close {
      button {
        background: transparent;
        border: 0;
        color: v(color-support-03);
        outline: 0;
        padding-left: v(spacing-scale-baseh);
      }
    }
  }
}

.br-tag-input {
  @include input;
  display: flex;
  flex-wrap: wrap;
  input {
    flex: 1;
  }
}
.input-tag {
  padding: 0.9em 0.5em;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}
