// Layout de autenticação
.template-authentication {
  .page-content {
    padding: $spacer;
  }
  .page-wrapper {
    position: relative;
  }
  .accessibility {
    position: absolute;
    right: $spacer * 2;
    top: $spacer * 2;
    .icon {
      font-size: 150%;
    }
    a {
      text-decoration: none;
    }
  }
  // Logo
  .logo {
    display: block;
    margin-top: $spacer;
    position: static;
    text-align: left;
  }
  // Marca do Governo
  .marca {
    width: 150px;
  }
  // Menu signin
  .signin-menu {
    a {
      align-items: center;
      color: v(color-secondary-08, $color-secondary-08);
      display: flex;
      margin-bottom: $spacer;
      margin-left: $spacer * 1.75;
      text-decoration: none;
      &:hover {
        color: v(status-hover-background, $status-hover-background);
      }
    }
    .icon {
      margin-right: $spacer;
    }
  }
  // Versão tablet
  @include media-breakpoint-up(sm) {
    .left-side {
      background-color: v(color-secondary-02, $color-secondary-02);
      .page-content {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .right-side {
      .page-content {
        padding-left: 60px;
      }
    }
    // Logo
    .logo {
      margin-bottom: 50px;
      margin-top: 100px;
      text-align: center;
    }
    .accessibility {
      position: static;
      text-align: right;
      .icon {
        font-size: 100%;
      }
    }
    .main-title {
      margin-top: 70px;
    }
  }
  // Desktop
  @include media-breakpoint-up(md) {
    .left-side {
      .page-content {
        margin-left: auto;
        max-width: 267px;
      }
    }
    .right-side {
      max-width: 632px;
      .page-content {
        padding-left: 120px;
      }
    }
  }
  // TV
  @include media-breakpoint-up(lg) {
    .right-side {
      max-width: 774px;
    }
  }
  // 4K
  @include media-breakpoint-up(xl) {
    .right-side {
      max-width: 918px;
    }
  }
}

@supports (display: grid) {
  .template-authentication {
    // Versão tablet
    @include media-breakpoint-up(sm) {
      .page-wrapper {
        display: grid;
        grid-template-areas: "left-side right-side";
        grid-template-columns: 30vw 1fr;
        grid-template-rows: 1fr;
      }
    }
    // Versão desktop
    @include media-breakpoint-up(md) {
      .page-wrapper {
        grid-template-columns: 37vw 1fr;
      }
    }
  }
}
