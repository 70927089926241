// Família de fontes base
$font-family-base: "Rawline", "Raleway", sans-serif;

// Pesos
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;
$tokens-font-weight: (
  font-weight-thin: $font-weight-thin,
  font-weight-extra-light: $font-weight-extra-light,
  font-weight-light: $font-weight-light,
  font-weight-regular: $font-weight-regular,
  font-weight-medium: $font-weight-medium,
  font-weight-semi-bold: $font-weight-semi-bold,
  font-weight-bold: $font-weight-bold,
  font-weight-extra-bold: $font-weight-extra-bold,
  font-weight-black: $font-weight-black,
);

$text-font-weight: (
  thin: $font-weight-thin,
  extra-light: $font-weight-extra-light,
  light: $font-weight-light,
  regular: $font-weight-regular,
  medium: $font-weight-medium,
  semi-bold: $font-weight-semi-bold,
  bold: $font-weight-bold,
  extra-bold: $font-weight-extra-bold,
  black: $font-weight-black,
);

// Tamanhos
$font-size-scale-base: 14px;
$font-size-scale-down-01: 0.833;
$font-size-scale-down-02: 0.694;
$font-size-scale-down-03: 0.579;
$font-size-scale-up-01: 1.2;
$font-size-scale-up-02: 1.44;
$font-size-scale-up-03: 1.728;
$font-size-scale-up-04: 2.074;
$font-size-scale-up-05: 2.488;
$font-size-scale-up-06: 2.986;
$font-size-scale-up-07: 3.583;
$tokens-font-size: (
  font-size-scale-base: $font-size-scale-base,
  font-size-scale-down-01: $font-size-scale-base * $font-size-scale-down-01,
  font-size-scale-down-02: $font-size-scale-base * $font-size-scale-down-02,
  font-size-scale-down-03: $font-size-scale-base * $font-size-scale-down-03,
  font-size-scale-up-01: $font-size-scale-base * $font-size-scale-up-01,
  font-size-scale-up-02: $font-size-scale-base * $font-size-scale-up-02,
  font-size-scale-up-03: $font-size-scale-base * $font-size-scale-up-03,
  font-size-scale-up-04: $font-size-scale-base * $font-size-scale-up-04,
  font-size-scale-up-05: $font-size-scale-base * $font-size-scale-up-05,
  font-size-scale-up-06: $font-size-scale-base * $font-size-scale-up-06,
  font-size-scale-up-07: $font-size-scale-base * $font-size-scale-up-07,
);

$text-font-size: (
  down-01: $font-size-scale-down-01,
  down-02: $font-size-scale-down-02,
  down-03: $font-size-scale-down-03,
  up-01: $font-size-scale-up-01,
  up-02: $font-size-scale-up-02,
  up-03: $font-size-scale-up-03,
  up-04: $font-size-scale-up-04,
  up-05: $font-size-scale-up-05,
  up-06: $font-size-scale-up-06,
  up-07: $font-size-scale-up-07,
);

// Altura de linhas
$font-line-height-low: 1.15;
$font-line-height-medium: 1.45;
$font-line-height-high: 1.85;
$tokens-line-height: (
  font-line-height-low: $font-line-height-low,
  font-line-height-medium: $font-line-height-medium,
  font-line-height-high: $font-line-height-high,
);
