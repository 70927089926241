.#{$prefix}checkbox {
  display: block;
  vertical-align: top;
  input[type='checkbox'] {
    // Remover o checkbox padrão do navegador
    opacity: 0;
    position: absolute;
    &:focus {
      &:checked + label:before,
      & + label::before {
        background: v(status-highlight-background)
          linear-gradient(v(status-focused-overlay), v(status-focused-overlay)) !important;
      }
    }

    &:disabled + label {
      cursor: not-allowed;
      opacity: 0.45;
    }
    // Caixa
    & + label {
      align-items: flex-start;
      color: v(color-secondary-07);
      cursor: pointer;
      display: inline-block;
      font-weight: v(font-weight-medium);
      min-height: v(spacing-scale-3x);
      padding: v(spacing-scale-half) v(spacing-scale-4x) v(spacing-scale-base) v(spacing-scale-4x);
      position: relative;
      font-size: v(font-size-scale-up-01);
      &:hover:before {
        border: 1px solid v(color-secondary-06, $color-secondary-06);
        box-shadow: 0 0 0 5px rgba(var(--rgb-primary-lighten-01), 0.45);
      }
      &:before {
        background: v(color-secondary-01, $color-secondary-01);
        border: 1px solid v(color-secondary-06, $color-secondary-06);
        border-radius: 4px;
        content: '';
        height: v(spacing-scale-3x);
        left: 0;
        position: absolute;
        width: v(spacing-scale-3x);
        padding: v(spacing-scale-half) v(spacing-scale-base) v(spacing-scale-half) 0;
        @include focus;
      }
      &:empty {
        padding-left: v(spacing-scale-3x);
      }
    }
    // Checado
    &:checked + label::after {
      border: solid v(color-primary-lighten-01);
      border-width: 0 3px 3px 0;
      content: '';
      height: v(icon-size-sm);
      left: 8px;
      position: absolute;
      top: 8px;
      transform: rotate(45deg);
      width: 8px;
    }
    // Inválido
    &:invalid {
      & + label::before {
        border-color: v(color-danger, $color-danger);
        border-width: 2px;
      }
    }
    // Desativado
    &:disabled + label::before {
      background-color: v(color-secondary-03, $color-secondary-03);
      pointer-events: none;
      box-shadow: none !important;
    }

    // Checado
    &:disabled:checked + label::before {
      background: v(color-secondary-05, $color-secondary-05);
      border-color: v(color-secondary-05, $color-secondary-05);
      pointer-events: none;
    }
  }

  // Estados
  $color-d: v(color-danger, $color-danger);
  $color-s: v(color-success, $color-success);
  @each $state, $color in (invalid, $color-d), (valid, $color-s) {
    &.is-#{$state}:not(.is-inverted):not(.inverted):not([inverted]),
    &.#{$state}:not(.is-inverted):not(.inverted):not([inverted]),
    &[#{$state}]:not(.is-inverted):not(.inverted):not([inverted]) {
      input[type='checkbox'] {
        & + label {
          color: $color;
          &:hover:before {
            @if ($state== 'invalid') {
              border-width: 2px;
              border-color: v(color-danger, $color-danger);
            } @else {
              border: 1px solid v(color-secondary-06, $color-secondary-06);
              box-shadow: 0 0 0 5px rgba(var(--rgb-primary-lighten-02), 0.45);
            }
          }
        }
        & + label::before {
          @if ($state== 'valid') {
            border-color: v(color-success, $color-success) !important;
            border-width: 2px;
          }
          @if ($state== 'invalid') {
            border-width: 2px;
            border-color: v(color-danger, $color-danger);
          }
        }
        &:focus {
          &:checked + label:before,
          & + label:before {
            background: v(status-highlight-background)
              linear-gradient(v(status-focused-overlay), v(status-focused-overlay)) !important;
            border-color: $color;
          }
        }
        // Checado
        &:checked + label::before {
          border-color: $color;
        }
      }
    }
  }

  // Versão pequena
  &.is-small,
  &.small,
  &[small] {
    input[type='checkbox'] {
      // Texto
      & + label {
        line-height: v(spacing-scale-2xh);
        min-height: v(spacing-scale-2xh);
      }

      // Caixa
      & + label::before {
        height: v(spacing-scale-2xh);
        width: v(spacing-scale-2xh);
      }

      // Checado
      &:checked + label::after {
        left: 7px;
        top: 6px;
        border-width: 0 2px 2px 0;
        height: v(icon-size-sm);
        width: 6px;
      }
    }
  }
}
