@mixin scrim {
  &.is-foco {
    background: rgba(v(rgb-secondary-09), 0.4);
    bottom: 0;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    .br-modal {
      -ms-transform: translate(-50%, -50%);
      background-color: v(color-secondary-01, $color-secondary-01);
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      z-index: 1000;
    }
    &.is-active {
      display: block;
    }
  }
  &.is-inibicao {
    position: relative;
    &::before {
      background: rgba(v(rgb-secondary-01), 0.64);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  &.is-legibilidade {
    position: relative;
    .scrim-text {
      background: rgba(v(rgb-secondary-09), 0.64);
      bottom: 0;
      left: 0;
      padding: v(spacing-scale-3x) v(spacing-scale-baseh);
      position: absolute;
      width: 100%;
    }
  }
}
