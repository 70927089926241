// Lista de Itens de Abas
.#{$prefix}tabs {
  &[data-counter="true"] {
    .tab-nav {
      ul {
        margin-bottom: v(spacing-scale-9x);
        .tab-item {
          &[not-tab="true"] {
            .name {
              color: v(color-secondary-07, $color-secondary-07);
              font-size: v(font-size-scale-up-03, $font-size-scale-up-03);
              line-height: v(font-size-scale-up-03, $font-size-scale-up-03);
              font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
              display: inline-block;
              padding-bottom: 6px;
            }
            .outcome {
              font-size: v(font-size-scale-up-02, $font-size-scale-up-02);
              font-weight: v(font-weight-regular, $font-weight-regular);
              position: absolute;
              top: 52px;
              left: 0;
              span {
                color: v(color-primary-default, $color-primary-default);
                display: inline-block;
                font-size: v(font-size-scale-up-03, $font-size-scale-up-03);
                font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
                margin-left: v(spacing-scale-3x);
              }
            }
          }
        }
      }
    }
  }
  .tab-nav {
    ul {
      border-bottom: 1px solid v(color-secondary-04, $color-secondary-04);
      display: flex;
      padding: 0;
      margin-bottom: v(spacing-scale-base);
      margin-top: 0;
      .tab-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 v(spacing-scale-3x);
        position: relative;
        white-space: nowrap;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &.is-active {
          button {
            border-bottom: 4px solid
              v(color-primary-default, $color-primary-default);
            padding-bottom: v(spacing-scale-base);
            .name {
              color: v(color-primary-darken-02, $color-primary-darken-02);
            }
          }
          .results {
            color: v(color-primary-default, $color-primary-default);
          }
        }
        button {
          background-color: transparent;
          border: none;
          border-bottom: 4px solid transparent;
          color: v(color-secondary-07, $color-secondary-07);
          display: inline-block;
          padding: v(spacing-scale-base) v(spacing-scale-3x);
          &:hover {
            background-color: v(
              color-primary-pastel-02,
              $color-primary-pastel-02
            );
            .name {
              color: v(color-primary-default, $color-primary-default);
            }
          }
          &:focus {
            background-color: rgba(242, 227, 23, 0.3);
            .name {
              color: v(color-primary-default, $color-primary-default);
            }
          }
          .name {
            color: v(color-secondary-07, $color-secondary-07);
            font-size: v(font-size-scale-up-02, $font-size-scale-up-02);
            font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
            text-align: center;
            white-space: nowrap;
          }
        }
        .results {
          color: v(color-secondary-07, $color-secondary-07);
          display: flex;
          font-size: v(font-size-scale-base, $font-size-scale-base);
          font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
          justify-content: center;
          position: absolute;
          top: 60px;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      overflow-x: auto;
      display: flex;
    }
  }
  .tab-content {
    .tab-panel {
      display: none;
      &.is-active {
        display: block;
      }
    }
  }
}
