$badge-min: 18px;
$badge-empty-min: 8px;

.#{$prefix}badge {
  display: inline-block;
  position: relative;
  .icon {
    background: v(color-secondary-03);
    border-radius: 40px;
    box-shadow: 0 0 0 1px v(color-secondary-01);
    display: inline-block;
    font-size: v(font-size-scale-down-01);
    font-weight: v(font-weight-semi-bold);
    min-height: $badge-min;
    min-width: $badge-min;
    padding: 0 v(spacing-scale-half);
    position: absolute;
    text-align: center;
    top: 0;
    z-index: 1;
    @include icon {
      --icon-size: v(icon-size-xs);
      width: auto;
    }
    &:first-child {
      right: calc(100% - #{v(spacing-scale-baseh)});
    }
    &:last-child {
      left: calc(100% - #{v(spacing-scale-baseh)});
    }
    &:empty {
      border-radius: 50%;
      min-height: $badge-empty-min;
      min-width: $badge-empty-min;
      padding: 0;
    }
  }
  .content {
    display: inline-block;
  }
  &.is-bottom,
  &.bottom,
  &[bottom] {
    .icon {
      bottom: 0;
      top: auto;
    }
  }
  // Tamanhos
  @each $key, $value in (medium: 1.5, large: 2.5) {
    &.is-#{$key},
    &.#{$key},
    &[#{$key}] {
      .icon {
        transform: scale($value);
        &:first-child {
          right: calc(100% - #{v(spacing-scale-base)} * 1.75 * #{$value});
        }
        &:last-child {
          left: calc(100% - #{v(spacing-scale-base)} * 1.75 * #{$value});
        }
        &:empty {
          min-height: $badge-min * 0.75;
          min-width: $badge-min * 0.75;
        }
      }
    }
  }
  // Cores
  @each $state in (success, warning, danger, info) {
    &.is-#{$state},
    &.#{$state},
    &[#{$state}] {
      .icon {
        background: var(--color-#{$state});
        @if $state != warning {
          color: v(color-secondary-01);
        }
      }
    }
  }
}
