.dropdown {
  position: relative;
  .#{$prefix}list {
    box-shadow: 0 3px 6px rgba(v(rgb-secondary-09), 0.16);
    position: absolute;
    z-index: 1;
  }
  // Menu aberto
  &:not(.show) {
    .#{$prefix}list {
      display: none;
    }
  }
}
