// Grid Bootstrap
@import '~bootstrap/scss/grid';

@include media-breakpoint-up(sm) {
  .container,
  .container-fluid {
    padding-left: $grid-tablet-margin;
    padding-right: $grid-tablet-margin;
  }
  .row {
    margin-right: -($grid-tablet-gutter / 2);
    margin-left: -($grid-tablet-gutter / 2);
  }
  .col,
  [class*='col-'] {
    padding-right: $grid-tablet-gutter / 2;
    padding-left: $grid-tablet-gutter / 2;
  }
}

@include media-breakpoint-up(xl) {
  .row {
    margin-right: -$grid-tv-gutter / 2;
    margin-left: -$grid-tv-gutter / 2;
  }
  .col,
  [class*='col-'] {
    padding-right: $grid-tv-gutter / 2;
    padding-left: $grid-tv-gutter / 2;
  }
}
