$column-height: 56px;
$column-compact: 40px;
$column-spacing: 12px;
$column-gap: 6px;

.#{$prefix}table {
  display: block; //grid;
  .header {
    order: 0;
  }
  .headers {
    display: none;
    order: 1;
  }
  .responsive {
    order: 2;
  }
  .footer {
    order: 3;
  }
  .top-bar {
    align-items: center;
    background-color: v(color-secondary-03, $color-secondary-03);
    display: flex;
    height: $column-height;
    padding-left: $column-spacing;
    padding-right: $column-spacing;
    .title {
      flex-grow: 1;
      line-height: v(font-line-height-medium, $font-line-height-medium);
      font-size: v(font-size-scale-up-02, $font-size-scale-up-02);
      font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
    }
    .br-button {
      border-color: transparent;
      outline: none;
      &:hover {
        background-color: v(color-primary-pastel-02, $color-primary-pastel-02);
        cursor: pointer;
      }
    }
    .divisor {
      border-right: 1px solid v(color-secondary-04, $color-secondary-04);
      height: 1.5em;
      margin: 0 5px 0 5px;
    }
    .grid-small-trigger,
    .grid-large-trigger,
    .divisor {
      display: none;
    }
  }
  .bottom-bar {
    align-items: center;
    background-color: v(color-secondary-01, $color-secondary-01);
    box-shadow: 0px -1px 3px 0px rgba(v(color-secondary-09, $color-secondary-09), 0.16);
    display: flex;
    height: $column-height;
    justify-content: space-between;
    padding-left: $column-spacing;
    padding-right: $column-spacing;
    .items {
      align-items: center;
      display: none;
      label {
        display: none;
      }
    }
    .pagination {
      align-items: center;
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      .selectors {
        align-items: center;
        display: flex;
        .br-input {
          label {
            display: none;
          }
        }
      }
      .arrows {
        .br-button {
          background-color: transparent;
          border-color: transparent;
          outline: none;
          &:focus,
          &:hover {
            color: v(color-primary-default, $color-primary-default);
          }
          &:hover {
            background-color: v(
              color-primary-pastel-02,
              $color-primary-pastel-02
            );
          }
        }
      }
    }
  }
  .search-bar {
    align-items: center;
    background-color: v(color-primary-default, $color-primary-default);
    bottom: 0;
    display: flex;
    height: 0;
    opacity: 0;
    padding: $column-spacing * 0.5 $column-spacing;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    transition: top 150ms;
    z-index: -1;
    .search-close {
      background-color: transparent;
      border-color: transparent;
      color: v(color-secondary-01, $color-secondary-01);
    }
    .br-input {
      flex-grow: 1;
      label {
        display: none;
      }
      button.icon {
        top: 0;
      }
    }
    &.is-active {
      opacity: 1;
      top: 0;
      z-index: 1;
      height: $column-height;
    }
  }
  .selected-bar {
    align-items: center;
    background-color: v(color-primary-default, $color-primary-default);
    bottom: 0;
    display: flex;
    height: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    left: 0;
    opacity: 0;
    padding: 0 11px; //$column-spacing * 0.5 $column-spacing;
    position: absolute;
    right: 0;
    top: 100%;
    transition: top 150ms;
    z-index: -1;
    .info {
      flex-grow: 1;
      white-space: nowrap;
      display: flex;
      align-items: center;
      .select-all {
        font-size: v(font-size-scale-up-03, $font-size-scale-up-03);
        margin-right: v(spacing-scale-half);
        padding: 0;
      }
    }
    .text,
    .count {
      padding-right: v(spacing-scale-half);
      line-height: v(font-line-height-low, $font-line-height-low);
      color: v(color-secondary-01, $color-secondary-01);
    }
    button {
      min-width: 32px;
      padding: 1px 0px;
      width: 32px;
      outline: 0;
      &:hover,
      &:focus {
        background-color: transparent !important;
        border-color: transparent !important;
      }
      span {
        display: none;
      }
    }
    .mobile {
      display: inline;
    }
    &.is-active {
      opacity: 1;
      top: 0;
      z-index: 2;
      height: $column-height;
    }
  }
  table {
    display: block;
    border-collapse: collapse;
    tr {
      display: block;
      position: relative;
      background: v(color-secondary-01, $color-secondary-01);
      color: v(color-secondary-07, $color-secondary-07);
      transition: max-height 500ms;
      max-height: 100em;
      height: auto;
      &.is-collapsed {
        max-height: 5em;
        overflow: hidden;
        td:nth-child(1) {
          max-height: 3.8em;
          overflow: hidden;
        }
      }
      &.is-selected {
        background: v(color-primary-lighten-01, $color-primary-lighten-01);
        color: v(color-secondary-01, $color-secondary-01);
        &.is-hover,
        &:hover {
          background: v(color-primary-lighten-01, $color-primary-lighten-01);
          color: v(color-secondary-01, $color-secondary-01) !important;
        }
      }
      &.is-hover,
      &:hover {
        background-color: v(color-primary-pastel-02, $color-primary-pastel-02);
        color: v(color-secondary-07, $color-secondary-07);
      }
    }
    th {
      color: v(color-primary-darken-01, $color-primary-darken-01);
      display: none;
      font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
      padding-left: $column-spacing;
      padding-right: $column-spacing;
    }
    td {
      display: block;
      padding: $column-spacing * 0.5 $column-spacing;
      &::before {
        color: v(color-primary-darken-01, $color-primary-darken-01);
        content: attr(data-th);
        display: block;
        font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
      }
      .col-collapse,
      .col-collapse:hover,
      .col-collapse:focus {
        display: block;
        position: absolute;
        right: 8px;
        top: 1.2em;
        min-width: 32px;
        padding: 1px 0px;
        border: 0;
        color: inherit;
        background: transparent !important;
      }
    }
    thead {
      display: block;
    }
    tbody {
      display: block;
      tr {
        box-shadow: 0 3px 6px
          rgba(v(color-secondary-09, $color-secondary-09), 0.16);
        margin: $column-spacing * 0.5 0;
        padding: $column-spacing * 0.5 0;
        height: auto;
      }
    }
  }
  &.is-checkbox {
    table tbody tr {
      td {
        padding-left: 52px;
        padding-right: 52px;
        .br-checkbox {
          margin-bottom: 0;
        }
        &:first-child {
          position: absolute;
          padding-right: v(spacing-scale-half);
          padding-left: v(spacing-scale-2x);
          top: 1em;
          width: 100%;
          .col-collapse {
            top: 0.3em;
          }
        }
      }
      &.is-collapsed td:nth-child(1) {
        max-height: auto;
        overflow: visible;
      }
      &.is-collapsed td:nth-child(2) {
        max-height: 3.8em;
        overflow: hidden;
      }
    }
  }
  &.is-datatable {
    .header {
      position: sticky;
      top: 0;
      z-index: 1;
    }
    .footer {
      bottom: 0;
      z-index: 3;
    }
  }
  .br-select {
    .select-selected {
      color: v(color-primary-lighten-01, $color-primary-lighten-01);
      border-radius: 0;
      border-color: transparent;
      padding-left: v(spacing-scale-base);
      padding-right: v(spacing-scale-base);
      [class*="fa-"] {
        margin-left: v(spacing-scale-base);
      }
      &:focus {
        box-shadow: none;
      }
      &:hover {
        border-bottom-color: v(
          color-primary-lighten-01,
          $color-primary-lighten-01
        );
      }
    }
    .select-items {
      bottom: 100%;
      min-width: 100px;
      max-height: 400px;
      overflow: auto;
    }
  }

  // Resolve bug na rolagem do firefox e limita aparecimento da barra de rolagem horizontal
  .header,
  .headers,
  .responsive {
    width: 100%;
    position: relative;
  }

  .syncscroll {
    overflow: auto;
  }

  // Versão tablet
  @include media-breakpoint-up(sm) {
    .top-bar {
      background-color: v(color-secondary-01, $color-secondary-01);
      title {
        line-height: v(font-line-height-medium, $font-line-height-medium);
        font-size: v(font-size-scale-up-03, $font-size-scale-up-03);
      }
      .grid-small-trigger,
      .grid-large-trigger,
      .divisor {
        display: inline-block;
      }
    }
    .selected-bar {
      padding: 0 1rem;
      .mobile {
        display: none;
      }
      .action-button {
        button {
          width: auto;
          min-width: auto;
          span {
            display: inline;
            padding-right: v(spacing-scale-base);
            padding-left: v(spacing-scale-3x);
          }
        }
      }
    }
    .bottom-bar {
      span.divider {
        margin: 0 v(spacing-scale-2x) 0 v(spacing-scale-base);
        &::before {
          content: none;
        }
        &::after {
          background-color: transparent;
          color: v(color-secondary-04, $color-secondary-04);
          content: "|";
          height: auto;
          width: auto;
        }
      }
      .items {
        display: flex;
        .divider {
          margin: 0 v(spacing-scale-2x) 0 v(spacing-scale-base);
        }
      }
      .pagination {
        flex-grow: 0;
        .divider {
          margin: 0 v(spacing-scale-base) 0 v(spacing-scale-2x);
        }
      }
      .br-list {
        .item {
          &.divider {
            margin: 0;
          }
        }
      }
    }
    .responsive {
      overflow: auto;
    }
    .headers {
      background-color: v(color-secondary-03, $color-secondary-03);
      display: block;
      overflow-y: hidden;
      position: sticky;
      top: 0;
      z-index: 2;
      .scroller {
        align-items: center;
        display: flex;
        height: $column-height;
        overflow-x: hidden;
      }
      .item {
        color: v(color-primary-darken-01, $color-primary-darken-01);
        font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
        padding: $column-gap * 2;
        white-space: nowrap;
        .br-checkbox {
          margin-bottom: 0;
        }
        &:first-child {
          padding-left: $column-spacing * 1.33;
        }
        &:last-child {
          padding-right: $column-spacing * 0.66;
        }
      }
    }
    table {
      display: table;
      margin-top: -$column-height + 16px;
      width: 100%;
      th {
        background-color: v(color-secondary-03, $color-secondary-03);
        display: table-cell;
      }
      td {
        border-bottom: 1px solid
          v(color-primary-pastel-01, $color-primary-pastel-01);
        display: table-cell;
      }
      tr {
        display: table-row;
        height: $column-height;
        th,
        td {
          // padding: $column-gap * 2;
          &:first-child {
            padding-left: $column-spacing * 1.33;
          }
          &:last-child {
            padding-right: $column-spacing * 0.66;
          }
        }
        &.is-hover,
        &:hover {
          background: v(color-primary-pastel-02, $color-primary-pastel-02);
          color: v(color-primary-darken-01, $color-primary-darken-01);
        }
      }
      thead {
        display: table-header-group;
      }
      tbody {
        display: table-row-group;
        td {
          white-space: nowrap;
          &::before {
            content: none;
          }
          .col-collapse {
            display: none;
          }
        }
        tr {
          box-shadow: none;
          margin: 0;
        }
      }
    }
    &.is-checkbox {
      table tbody tr {
        td {
          padding-left: v(spacing-scale-base);
          padding-right: v(spacing-scale-base);
          &:first-child {
            position: static;
            padding-right: v(spacing-scale-2x);
            padding-left: v(spacing-scale-2x);
            width: auto;
          }
        }
      }
    }
    &.is-datatable {
      .headers {
        top: $column-height;
      }
      table {
        thead {
          th {
            top: $column-height;
          }
        }
        tbody {
          tr {
            &.hover {
              background-color: v(
                color-primary-pastel-02,
                $color-primary-pastel-02
              );
            }
          }
        }
      }
    }
    &.is-grid-small {
      .top-bar,
      .selected-bar.is-active,
      .search-bar.is-active,
      .bottom-bar,
      .headers .scroller {
        height: $column-compact;
        overflow: hidden;
      }
      .search-bar {
        .br-input.is-medium input,
        .br-input.is-medium .icon {
          height: $column-spacing * 2;
          line-height: $column-spacing * 2;
        }
      }
      table {
        margin-top: -$column-compact;
        tr {
          height: $column-compact;
        }
      }
      &.is-datatable {
        .headers {
          top: $column-compact;
        }
        table {
          thead {
            th {
              top: $column-compact;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(sm) {
    .responsive {
      table {
        thead {
          tr {
            height: 0;
          }
        }
        tbody {
          tr {
            height: 100%;
          }
        }
      }
    }
  }
}
