.#{$prefix}item {
  --item-bg: var(--bg-color);
  --item-color: var(--text-color);
  --item-font-weight: var(--font-weight);
  --item-padding-x: var(--spacing-scale-2x);
  --item-padding-y: var(--spacing-scale-base);
  --item-padding: var(--item-padding-y) var(--item-padding-x);
  background: v(item-bg);
  border: 0;
  color: v(item-color);
  display: block;
  font-weight: v(item-font-weight);
  padding: v(item-padding);
  position: relative;
  // Com separador
  &.divider {
    border-bottom: 1px solid v(color-secondary-04);
  }
  // Rotulagem
  &[data-tag] {
    position: relative;
    text-indent: 32px;
    &::before {
      border-radius: 50%;
      box-shadow: inset 0 0 0 1px var(--item-color);
      content: "";
      height: 20px;
      position: absolute;
      transform: translateX(-32px);
      width: 20px;
    }
  }
  @each $key, $value in $br-colors {
    &.bg-#{$key} {
      --item-bg: var(--color-#{$key});
      @if (lightness($value) < 50) {
        --item-color: var(--color-secondary-01);
        .#{$prefix}button {
          &:not(:focus):not(:hover) {
            color: v(color-secondary-01);
          }
        }
      }
    }
    &[data-tag="bg-#{$key}"] {
      &::before {
        background: $value;
        box-shadow: none;
      }
    }
  }
  // Com dropdown
  .dropdown {
    .#{$prefix}list {
      margin-top: var(--item-padding-y);
    }
  }
  // Regras para Grid interna
  .row {
    margin-left: calc(-1 * var(--spacing-scale-base));
    margin-right: calc(-1 * var(--spacing-scale-base));
    .col,
    [class*="col-"] {
      padding-left: v(spacing-scale-base);
      padding-right: v(spacing-scale-base);
    }
  }
  // Regras para Checkbox e Radiobutton
  .#{$prefix}checkbox,
  .#{$prefix}radio {
    input[type="checkbox"],
    input[type="radio"] {
      + label {
        color: v(item-color);
        display: block;
        font-size: v(font-size-scale-base);
        padding-left: v(spacing-scale-5x);
        padding-right: 0;
        &:empty {
          padding-left: v(spacing-scale-3x);
          padding-right: 0;
        }
      }
    }
  }
  // Fix para floats internos
  &::after {
    clear: both;
    content: "";
    display: block;
  }
  // Estados
  &.selected {
    --item-bg: var(--status-selected-background);
    --item-color: var(--status-selected-text);
  }
  &[disabled],
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    * {
      pointer-events: none;
    }
  }
}

// Item aplicado em link/botão
a.#{$prefix}item,
button.#{$prefix}item {
  @include focus;
  @include hover;
  @include active;
}
a.#{$prefix}item {
  --item-color: var(--link-color);
}
